
import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { fileCategories } from '../../utils/enums';
import translate from '../../utils/translate';

const DocumentUploadForm = observer(props => {
    const {
        editingFile,
        confirm,
        handleFormChange,
        isOpen,
        handleClose,
        defaultCategory,
        handleFileChange,
        fileSelected,
        setEditingErrorsAttribute,
        editingErrors } = props;

    useEffect(() => {
        if (isOpen) handleFormChange("category", defaultCategory);
    }, [isOpen]);

    const handleChange = ({ target }) => {
        handleFormChange(target.name, target.value);
    };

    const inputFile = useRef(null);

    const clickHandle = () => {
        inputFile.current.click();
    }

    const validateInputs = () => {
        let valid = true;
        if (editingFile.name === undefined || editingFile.name == null || editingFile.name === '') {
            setEditingErrorsAttribute('name', 'Insira um nome válido.');
            valid = false;
        }
        if (editingFile.category === undefined || editingFile.category == null || editingFile.category === '') {
            setEditingErrorsAttribute('category', 'Escolha uma categoria.');
            valid = false;
        }
        if (fileSelected === undefined || fileSelected == null || fileSelected === '') {
            setEditingErrorsAttribute('file', 'Escolha um arquivo para upload.');
            valid = false;
        }

        return valid;
    }

    const confirmClick = () => {
        if (validateInputs()) {
            confirm();
        }
    }

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{editingFile?.id?'Nova Versão de Documento':'Enviar Arquivo'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
                </DialogContentText> */}
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Nome do Arquivo"
            type="name"
            value={editingFile ? editingFile.name || "" : ""}
            error={!!editingErrors.name}
            helperText={editingErrors.name}
            onChange={handleChange}
            fullWidth
            disabled={!!editingFile?.id}
          />

          <FormControl fullWidth>
            <InputLabel id="category-select-label">Categoria</InputLabel>
            <Select
              required
              labelId="category-select-label"
              id="category-select"
              name="category"
              value={editingFile ? editingFile.category || "" : ""}
              error={!!editingErrors.category}
              onChange={handleChange}
              autoWidth={true}
              disabled={!!editingFile?.id}
            >
              {Object.entries(fileCategories).map(category => {
                return (
                  <MenuItem value={category[0]} key={category[0]}>
                    {translate.category(category[1])}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box display="flex" alignItems="center" marginTop="12px">
            <Box marginRight="12px">
              <TextField
                autoFocus
                id="name"
                name="name"
                label="Arquivo"
                type="name"
                value={fileSelected ? fileSelected.name || "" : ""}
                error={!!editingErrors.file}
                helperText={editingErrors.file}
                variant="outlined"
                disabled
                onClick={clickHandle}
                required
                // onChange={handleChange}
              />
            </Box>
            <input
              hidden
              onChange={handleFileChange}
              type="file"
              ref={inputFile}
              id="contained-button-file"
              name="file"
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Escolher
              </Button>
            </label>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmClick} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    );
});

DocumentUploadForm.propTypes = {
    editingFile: PropTypes.object,
    confirm: PropTypes.func.isRequired,
    handleFormChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    defaultCategory: PropTypes.oneOf(Object.keys(fileCategories)),

}

DocumentUploadForm.defaultProps = {
    defaultCategory: fileCategories.COMMON,
}

export default DocumentUploadForm;