import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';


import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';

const RightFab = styled(Fab)`
  right: ${props => props.theme.spacing(2)}px;
  bottom: 5px;
  position: fixed;
  margin: ${props => props.theme.spacing(1)}px;
`;

const LeftFab = styled(Fab)`
  left: ${props =>
    props.open ? props.theme.spacing(7) : props.theme.spacing(2)};
  bottom: 5px;
  position: fixed;
  margin: ${props => props.theme.spacing(1)}px;

`;

const FloatActionButton = (props) => {
    if (props.left) {
        return (
            <LeftFab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                disabled={props.disabled}
                onClick={props.onClick}
                open = {props.open}>
                {props.children}
            </LeftFab>
        )
    }
    return (
        <RightFab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            disabled={props.disabled}
            onClick={props.onClick}>
            {props.children}
        </RightFab>
    )
}

export default FloatActionButton;