import { inject, observer } from 'mobx-react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useEffect } from 'react';

import ClientActionStatus from 'components/documents/ClientActionStatus';
import ClientActionDocuments from 'components/documents/ClientActionDocuments';
import DownloadSnackbar from 'components/ui/DownloadSnackbar';

import { fileCategories } from '../../utils/enums';
import { actionStatus } from '../../utils/enums';

const Tests = inject("documentStore", "clientStore")(observer((props) => {
    const { documentStore, clientStore } = props;
    const { testsDocuments, inProgress } = documentStore;
    const { selectedClient } = clientStore;
    const status = selectedClient ? clientStore.getStatus(selectedClient.tests) : actionStatus.NAO_INICIADA;

    useEffect(() => {
        if (selectedClient) {
            handleRefresh();
        } else {
            documentStore.resetLists();
        }
    }, [selectedClient]);

    const handleRefresh = () => {
        documentStore.loadTestsDocuments();
    }

    return (
        inProgress ?
            <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
            :
            (<Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                <Box>
                    <ClientActionStatus title='Testes AVSEC' status={status} />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} height="0">
                    <ClientActionDocuments
                        handleRefresh={handleRefresh}
                        fileData={testsDocuments}
                        type="TESTS" />
                </Box>
            </Box>)
    );
}))

export default Tests;