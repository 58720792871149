
import * as React from "react";
import {  withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MUIToolTip from "@material-ui/core/Tooltip";
import { classes } from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import { Grow } from "@material-ui/core";

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: 16,

  },
  popperArrow:{
    zIndex: 102,
  },
  tooltipArrow: {
    backgroundColor: theme.palette.primary.main
  }
}))(MUIToolTip);

const Tooltip = props => (
  <StyledTooltip
    {...props}
    TransitionProps={{ timeout: 800 }}
  />
);

export default Tooltip;

