import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, withRouter } from "react-router-dom";
import Dashboard from "pages/Dashboard";
import Audit from "pages/ClientActions/Audit";
import PrivateRoute from "components/PrivateRoute";

const HomeSelector = inject("userStore")(
  withRouter(
    observer(props => {
      const { userStore, path, ...restProps } = props;
      if (userStore.isCompany) {
        return <PrivateRoute path="/" component={Dashboard} />;
      }
      return <PrivateRoute path="/" component={Audit} />;
    })
  )
);

HomeSelector.propTypes = {};

export default HomeSelector;
