import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';
import userStore from './userStore';
import commonStore from './commonStore';
import clientStore from './clientStore';

class ClientUserStore {

  constructor() {
    makeObservable(this)
  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable editingUser = undefined;
  @observable editingUserErrors = {};

  @observable users = [];

  //======================Users Section==================================
  @action newEditingUser () {
    this.editingUser = {
      id: null,
      tempId: Date.now(),
      username: "",
      email: "",
      password: "",
      name: "",
      profile: "CLIENT"
    };
  }

  @action setEditingUser (value) {
    this.editingUser = { ...value };
  }

  @action setUsers (value) {
    if (value) {
      this.users = value;
    }
  }

  @action deleteEditingUser (user) {
    if (user.id) {
      this.inProgress = true;
      return agent.User.delete(user.id)
        .then(() => {
          this.reset();
          this.loadUsers();
        })
        .catch(action((err) => {
          this.errors = err.response && err.response.body && err.response.body.errors && err.response.body.errors.body;
          commonStore.handleError(this.errors);
          this.inProgress = false;
          throw err;
        }));
    } else {
      this.removeUserFromArray(user);
    }
  }


  @action saveUser () {
    if (this.editingUser.id) {
      let id = this.editingUser.id;
      let foundUser = this.users.find((item) => item.id == id);
      this.users[this.users.indexOf(foundUser)] = this.editingUser;
    } else {
      let tempId = this.editingUser.tempId;
      let foundUser = this.users.find((item) => item.tempId == tempId);
      if (foundUser) {
        this.users[this.users.indexOf(foundUser)] = this.editingUser;
      } else {
        this.users.push(this.editingUser);
      }
    }
    this.clearEditingUser();
  }

  @action loadUsers () {
    this.inProgress = true;
    return agent.Client.get(clientStore.editingClient.id)
      .then(action((data) => {
        this.users = data.users;
      }))
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors && err.response.body.errors.body;
        commonStore.handleError(this.errors);
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  @action setEditingUserAttribute = (attr, value) => {
    this.editingUser[attr] = value;
  };

  @action setEditingUserErrorsAttribute = (attr, value) => {
    this.editingUserErrors[attr] = value;
  };

  //=========================Common=========================
  @action reset () {
    this.users = [];
    this.clearEditingUser();
  }

  @action clearEditingUser () {
    this.editingUser = undefined;
    this.clearEditingUserErrors();
  }

  @action clearEditingUserErrors(){
    this.editingUserErrors = {};
  }
  isEditingUser () {
    return !(this.editingUser === undefined);
  }

  removeUserFromArray (value) {
    let index = this.users.indexOf(value);
    if (index > -1) {
      this.users.splice(index, 1);
    }
  }
}

export default new ClientUserStore();
