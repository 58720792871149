import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RedError from 'components/RedError'

const useStyles = makeStyles((theme) => ({
  errorLabel: {
    color: theme.palette.error.main,
  }
}));

const ListErrors = (props) => {
  const errors = Array.isArray(props.errors) ? props.errors : props.errors ? [props.errors] : null;
  if (errors) {
    return (
      <div className="error-messages">
        {
          errors.map(error => {
            return (
              <RedError message={error.message} key={error.code} />
            );
          })
        }
      </div>
    );
  } else {
    return null;
  }
}

export default ListErrors;
