import { inject, observer } from "mobx-react";
import styled from "styled-components";
import React, { useState } from "react";

import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import {
  green,
  yellow,
  red,
  purple,
  orange,
  blue
} from "@material-ui/core/colors";

import { actionStatus } from "../../utils/enums";
import translate from "utils/translate";

const StatusBadge = styled(Button)`
  color: ${props =>
    props.customcolor === yellow[500]
      ? props.theme.palette.common.black
      : props.theme.palette.common.white};
  background-color: ${props => props.customcolor || green[500]};
  &:hover {
    background-color: ${props => props.customcolor || green[500]};
    cursor: default;
  }
`;

const ClientActionStatusBadge = inject("clientStore")(
  observer(props => {
    const { size } = props;

    const actionMessageStatusColor = translate.actionStatusColorMessage(
      props.status
    );

    let color = actionMessageStatusColor.color;
    let message = actionMessageStatusColor.message;

    return (
      <StatusBadge
        variant="contained"
        disableElevation
        disableFocusRipple
        disableRipple
        href=""
        customcolor={color}
        size={size}
      >
        {message}
      </StatusBadge>
    );
  })
);

export default ClientActionStatusBadge;
