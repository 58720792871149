import dayjs from 'utils/dayjs';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ptBR } from 'date-fns/locale'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';


import IconButton from '@material-ui/core/IconButton';
import MaterialContainer from '@material-ui/core/Container';
import { withTheme } from "@material-ui/core/styles"
import Divider from '@material-ui/core/Divider';

import ClientActionStatusBadge from 'components/documents/ClientActionStatusBadge'
import { actionStatus } from '../../utils/enums'

const Title = styled.label`
    font-size: 20px;
    margin-bottom: 20px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px 14px;
`;

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    padding: 8px;
`
const Separator = styled.div`
    margin: 0 4px;
`;

const ActionItemDetails = observer(props => {
    const startDate = props.startDate ? dayjs(props.startDate).format('DD/MM/YYYY') : "";
    const expectedEndDate = props.expectedEndDate ? dayjs(props.expectedEndDate).format('DD/MM/YYYY') : "";
    const endDate = props.endDate ? dayjs(props.endDate).format('DD/MM/YYYY') : "";
    return (
        <Container>
            <RowContainer>
                <label>Situação:</label>
                <ClientActionStatusBadge status={props.situation} size="small" />
            </RowContainer>
            <Divider />
            <RowContainer>
                <label>Data Início:</label>
                <label>{startDate}</label>
            </RowContainer>
            <Divider />
            <RowContainer>
                <label>Prazo:</label>
                <label>{expectedEndDate}</label>
            </RowContainer>
            <Divider />
            {props.endDate &&
                <RowContainer>
                    <label>Data Fim:</label>
                    <label>{endDate && endDate !== '' ? endDate : ''}</label>
                </RowContainer>
            }
        </Container>
    );
});


const UserDialog = observer(props => {
    const { user } = props;

    const save = () => {
        props.setClientActionDate();
        props.handleClose();
    };

    const handleDateChange = (date) => {
        props.setSelectedDate(date);
    };

    return (
        <Dialog open={props.modalState} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id={"form-dialog-title-" + props.action}>Iniciar {props.action}</DialogTitle>
            <DialogContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Data Início"
                        format="dd/MM/yyyy"
                        value={props.selectedDate}
                        onChange={handleDateChange}
                        invalidDateMessage="Data inválida"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        cancelLabel="Cancelar" todayLabel="Hoje"
                        autoOk showTodayButton
                    />
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={save} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
});

const ClientActionItem = inject("clientStore")(observer((props) => {

    const [modalOpen, setModalOpen] = useState(false);

    const handleClickOpen = () => {
        props.setSelectedDate(props.startDate && props.startDate !== '' ? props.startDate : new Date())
        setModalOpen(true)
    };

    const handleClose = () => {
        setModalOpen(false)
    };

    const handleClickFinalizar = () => {
        props.endActionItem();
    };
    const iniciarDisabled = !props.transient && (props.status === actionStatus.EM_ANDAMENTO || props.status === actionStatus.ATRASADA);
    const finalizarDisabled = props.transient || props.status === '' || !props.status || props.status === actionStatus.NAO_INICIADA || props.status === actionStatus.CONFORME || props.status.FINALIZADA_ATRASO || props.status === actionStatus.AGENDADA;

    const startText = props.startDate && props.startDate !== '' && (!props.endDate || props.endDate === '') ? "Alterar" : "Iniciar";
    return (
        <Container>
            <RowContainer>
                <Title>{props.title}</Title>
                {props.icon}
            </RowContainer>

            <ActionItemDetails startDate={props.startDate} endDate={props.endDate} expectedEndDate={props.expectedEndDate} situation={props.status} />
            <RowContainer>
                <Button variant="contained" color="primary" onClick={handleClickOpen}
                    disabled={iniciarDisabled}
                    fullWidth>
                    {startText}
                </Button>
                <Separator />
                <Button variant="contained" color="primary" onClick={handleClickFinalizar}
                    disabled={finalizarDisabled}
                    fullWidth>
                    Finalizar
                </Button>
            </RowContainer>

            <UserDialog handleOpen={handleClickOpen}
                handleClose={handleClose}
                modalState={modalOpen}
                setClientActionDate={props.setClientActionDate}
                setSelectedDate={props.setSelectedDate}
                selectedDate={props.selectedDate}
                action={props.title} />
        </Container>
    );
}));

export default ClientActionItem;
