import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";


class CustomToolbar extends React.Component {

    render () {
        const { handleRefresh, refreshEnabled } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"Atualizar"}>
                    <span>
                        <IconButton disabled={!refreshEnabled} onClick={handleRefresh}>
                            <RefreshIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </React.Fragment>
        );
    }

}

export default (CustomToolbar);
