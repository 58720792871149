import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useRef } from 'react';

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ConfirmationDialog from 'components/ui/ConfirmationDialog';

const Title = styled.label`
    font-size: 20px;
    margin-bottom: 20px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px 14px;
    //Below will work now!
    background-color: ${props => props.theme.palette};
`;

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: stretch;
`

const CustomListItemIcon = styled(ListItemIcon)`
  min-width:28px;
`;

const Separator = styled.div`
    margin: 0 4px;
`;

const Logotipo = styled.img`
    width: 100%;
    margin-bottom:10px;
`

const ClientLogo = inject("clientStore", "commonStore")(observer((props) => {

    const { clientStore, commonStore } = props;
    const { currentLogoUploading, editingClient } = clientStore;

    const validateFileTypes = (filename) => {
        var parts = filename.split(".");
        const fileType = parts[parts.length - 1];

        const allowed = process.env.REACT_APP_LOGO_TYPE_ALLOWED.split('|');

        if (!allowed.includes(fileType)) {
            commonStore.enqueueErrorSnackbar('Apenas formatos png suportados.');
            return false;
        }

        return true;
    }

    const handleFileChange = (e) => {
        const { files } = e.target;
        if (files && files.length) {
            const file = files[0];
            if (file && validateFileTypes(file.name)) {
                clientStore.setUploadingLogo(file);
            }
        }

        // URL.createObjectURL(file);
    }

    const handleSaveLogo = () => {
        props.handleLogoUpload();
    };

    const inputFile = useRef(null);

    const clickHandle = () => {
        inputFile.current.click();
    }

    const logoSrc = currentLogoUploading ? URL.createObjectURL(currentLogoUploading) : editingClient.logo;

    return (
        <Container>
            <Title>Logotipo </Title>
            <Logotipo src={logoSrc} />
            <RowContainer>
                <input
                    hidden
                    onChange={handleFileChange}
                    type="file"
                    ref={inputFile}
                    id="contained-button-file"
                    name="file"
                />
                <Button variant="contained" color="primary" onClick={clickHandle} fullWidth>
                    Escolher
                    </Button>
                <Separator />
                <Button variant="contained" color="primary" onClick={handleSaveLogo} disabled={!currentLogoUploading} fullWidth>
                    Salvar
                    </Button>
            </RowContainer>

        </Container>
    );

}));

export default ClientLogo;
