import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ClientActionStatusBadge from 'components/documents/ClientActionStatusBadge';

const Separator = styled.div`
    margin: 0 4px;
`;

const ClientActionStatus = inject("clientStore")(observer((props) => {

    return (
        <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center" margin="12px" marginLeft="24px" >
            <Typography variant="h6">
                {props.title}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                <Typography color="textSecondary">
                    Status:
                </Typography>
                <Separator/>
                <ClientActionStatusBadge status={props.status}/>
            </Box>
        </Box>
    );
}));

export default ClientActionStatus;
