import MaterialTable from "material-table";

import MUIDataTable, { TableBody } from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import Box from "@material-ui/core/Box";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import translate from "../../utils/translate";
import { isMobileView } from "utils/breakpoints";
import { actions } from "utils/enums";

const ClientList = props => {
  const { theme } = props;
  let clientList = props.list;
  const mobile = isMobileView();
  const { actionFilter, statusFilter: statusFilterRaw } = props;
  let statusFilter = [];
  for (let status of statusFilterRaw) {
    statusFilter.push(translate.actionStatusColorMessage(status).message);
  }

  let statuses = translate.getStatusesFriendlyNames();

  const columns = [
    {
      name: "name",
      label: "Nome",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "cnpj",
      label: "CNPJ",
      options: {
        filter: false,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => (
          <NumberFormat
            format="##.###.###/####-##"
            id="cnpj-input"
            label="CNPJ"
            name="cnpj"
            value={value}
            displayType={"text"}
          />
        )
      }
    },
    {
      name: "airport",
      label: "Aeroporto",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "statusAudit",
      label: "Status Auditoria",
      options: {
        filter: true,
        sort: false,
        display: false,
        filterList: actionFilter === actions.AUDIT ? [...statusFilter] : [],
        filterOptions: {
          names: [...statuses]
        }
      }
    },
    {
      name: "statusInspection",
      label: "Status Inspeção",
      options: {
        filter: true,
        sort: false,
        display: false,
        filterList:
          actionFilter === actions.INSPECTION ? [...statusFilter] : [],
        filterOptions: {
          names: [...statuses]
        }
      }
    },
    {
      name: "statusTests",
      label: "Status Testes",
      options: {
        filter: true,
        sort: false,
        display: false,
        filterList: actionFilter === actions.TESTS ? [...statusFilter] : [],
        filterOptions: {
          names: [...statuses]
        }
      }
    },
    {
      name: "Ação",
      options: {
        filter: false,
        sort: false,
        empty: true,
        searchable: false,
        viewColumns: false,
        customBodyRenderLite: function action(dataIndex, rowIndex) {
          return (
            <Box>
              <IconButton
                aria-label="Edit"
                onClick={() => props.editClick(clientList[dataIndex])}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="Delete"
                onClick={() => props.deleteClick(clientList[dataIndex].id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        },
        setCellProps: value => {
          return {
            style: {
              textAlign: mobile ? "left" : "center"
            }
          };
        },
        setCellHeaderProps: value => {
          return {
            style: {
              textAlign: "center"
            }
          };
        }
      }
    }
  ];

  const options = {
    filter: !mobile,
    filterType: "dropdown",
    responsive: "vertical",
    elevation: 0,
    download: false,
    print: false,
    selectableRows: "none",
    tableBodyHeight: "100%",
    rowsPerPage: 15,
    textLabels: translate.textLabels(),
    viewColumns: !mobile
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height="100%"
      bgcolor="transparent"
      clone
    >
      <MUIDataTable
        title={"Meus Clientes"}
        data={clientList.map(item => {
          return {
            id: item.id,
            name: item.name,
            cnpj: item.cnpj,
            city: item.address.city,
            airport: item.airport,
            statusAudit: translate.actionStatusColorMessage(item.statusAudit)
              .message,
            statusInspection: translate.actionStatusColorMessage(
              item.statusInspection
            ).message,
            statusTests: translate.actionStatusColorMessage(item.statusTests)
              .message
          };
        })}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

export default ClientList;
