import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';

import { fileCategories } from '../../utils/enums';
import ClientActionStatus from 'components/documents/ClientActionStatus';
import ClientActionDocuments from 'components/documents/ClientActionDocuments';
import DownloadSnackbar from 'components/ui/DownloadSnackbar';

import { actionStatus } from '../../utils/enums';

const Audit = inject("documentStore", "clientStore")(observer((props) => {

    const { documentStore, clientStore } = props;
    const { auditDocuments, inProgress } = documentStore;
    const { selectedClient } = clientStore;
    const status = selectedClient ? clientStore.getStatus(selectedClient.audit) : actionStatus.NAO_INICIADA;

    useEffect(() => {
        if (selectedClient) {
            handleRefresh();
        } else {
            documentStore.resetLists();
        }
    }, [selectedClient]);

    const handleRefresh = () => {
        documentStore.loadAuditDocuments();
    }

    return (
        inProgress ?
            <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
            :
            (<Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                <Box>
                    <ClientActionStatus title='Auditoria AVSEC' status={status} />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} height="0">
                    <ClientActionDocuments
                        handleRefresh={handleRefresh}
                        fileData={auditDocuments}
                        type={fileCategories.AUDIT} />
                </Box>
            </Box>)
    );
}))

export default Audit;