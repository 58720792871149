import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

const allowedRoutes = new (function() {
  this.CLIENT = [
    "/",
    "/audit",
    "/inspection",
    "/tests",
    "/profile",
    "/documents"
  ];
  this.EMPLOYEE = [...this.CLIENT, "/client", "/clients", "/dashboard"]; //TODO: INCLUIR '/dashboard'
  this.ADMIN = [...this.EMPLOYEE, "/employees"];
})();
@inject("userStore")
@observer
class PrivateRoute extends React.Component {
  render() {
    const { userStore, path, ...restProps } = this.props;
    if (userStore.currentUser) {
      if (!allowedRoutes[userStore.getUserProfile].includes(path)) {
        console.log("not allowed");
        return <Redirect to="/" />;
      }
      return <Route {...restProps} />;
    }
    return <Redirect to="/login" />;
  }
}

export default PrivateRoute;
