import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { computed } from "mobx";
import { observer } from "mobx-react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link_Material from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import ListErrors from "components/ListErrors";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link_Material color="inherit" href="http://qsmsquality.com.br/">
        QSMS
      </Link_Material>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(images/auth/plane3.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const UpdatePassword = props => {
  const {
    handlePasswordChange,
    handlePasswordConfirmationChange,
    handleSubmitForm,
    inProgress,
    errors,
    passwordValidationError
  } = props;

  const passwordErrorEnabled = computed(
    () =>
      passwordValidationError != null &&
      passwordValidationError != undefined &&
      passwordValidationError != ""
  ).get();
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            src={process.env.PUBLIC_URL + "/logo_qsms_vertical.png"}
            alt="Logo"
            height="180em"
          />
          <Typography component="h1" variant="subtitle1">
            Digite sua nova senha.
          </Typography>
          <ListErrors errors={errors} />
          <form className={classes.form} noValidate onSubmit={handleSubmitForm}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              type="password"
              label="Nova Senha"
              name="password"
              autoFocus
              error={passwordErrorEnabled}
              helperText={passwordValidationError}
              autoComplete="new-password"
              // value={values.email}
              onChange={handlePasswordChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password_confirmation"
              type="password"
              label="Confirmar Nova Senha"
              name="password_confirmation"
              autoFocus
              error={passwordErrorEnabled}
              helperText={passwordValidationError}
              // value={values.email}
              onChange={handlePasswordConfirmationChange}
            />

            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={inProgress}
              >
                Enviar
              </Button>
              {inProgress && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

UpdatePassword.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  inProgress: PropTypes.bool
};

export default UpdatePassword;
