import React from "react";
import { inject, observer } from "mobx-react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import InspectionIcon from "@material-ui/icons/Visibility";
import TestIcon from "@material-ui/icons/PlaylistAddCheck";
import LogoutIcon from "@material-ui/icons/ExitToApp";

import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Link, withRouter } from "react-router-dom";

export const MainListItems = inject("clientStore", "authStore")(
  withRouter(
    observer(props => {
      const { isAdmin, isEmployee } = props;

      return (
        <div>
          {(isAdmin || isEmployee) && (
            <ListItem button component={Link} to="/dashboard" replace>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}
          {(isAdmin || isEmployee) && (
            <ListItem
              button
              onClick={() => {
                props.clientStore.clearFilters();
                props.history.replace("/clients");
              }}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItem>
          )}

          <ListItem button component={Link} to="documents" replace>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Documentos" />
          </ListItem>
          <ListItem button component={Link} to="audit" replace>
            <ListItemIcon>
              <VerifiedUserIcon />
            </ListItemIcon>
            <ListItemText primary="Auditorias" />
          </ListItem>
          <ListItem button component={Link} to="inspection" replace>
            <ListItemIcon>
              <InspectionIcon />
            </ListItemIcon>
            <ListItemText primary="Inspeção" />
          </ListItem>
          <ListItem button component={Link} to="tests" replace>
            <ListItemIcon>
              <TestIcon />
            </ListItemIcon>
            <ListItemText primary="Teste" />
          </ListItem>
          {isAdmin && (
            <ListItem button component={Link} to="employees" replace>
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Funcionários" />
            </ListItem>
          )}
        </div>
      );
    })
  )
);

export const SecondaryListItems = inject("authStore")(
  withRouter(
    observer(props => {
      const { isAdmin } = props;
      return (
        <div>
          <ListItem
            button
            onClick={() => {
              props.authStore.logout().then(() => props.history.replace("/"));
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </div>
      );
    })
  )
);
