import React, { useRef } from "react";
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { fileCategories } from '../utils/enums';
import ClientActionDocuments from 'components/documents/ClientActionDocuments';
import DownloadSnackbar from 'components/ui/DownloadSnackbar';

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
`;



const Documents = inject("documentStore", "clientStore")(observer((props) => {

    const { documentStore, clientStore } = props;
    const { inProgress, commonDocuments } = documentStore;
    const { selectedClient } = clientStore;
    useEffect(() => {
        if (selectedClient) {
            handleRefresh();
        } else {
            documentStore.resetLists();
        }
    }, [selectedClient]);

    const handleRefresh = () => {
        documentStore.loadCommonDocuments();
    }

    return (
        inProgress ?
            <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
            :
            <FlexDiv>
                <ClientActionDocuments
                    title="Documentos Gerais"
                    fileData={commonDocuments}
                    handleRefresh={handleRefresh}
                    isUploadingFile={documentStore.isUploadingFile}
                    setCurrentFileUploading={documentStore.setCurrentFileUploading}
                    type={fileCategories.COMMON}
                />
            </FlexDiv>
    );
}))

export default Documents;