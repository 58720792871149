import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import promiseFinally from "promise.prototype.finally";
import { configure } from "mobx";
import { makeStyles } from '@material-ui/core/styles';
import { Provider } from "mobx-react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import styled, {ThemeProvider } from "styled-components";
import { MuiThemeProvider, StylesProvider, createMuiTheme, jssPreset } from "@material-ui/core/styles";
import { SnackbarProvider } from 'notistack';
import { create } from 'jss';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { green, brown, red, purple } from '@material-ui/core/colors';

import authStore from "./stores/authStore";
import commonStore from "./stores/commonStore";
import userStore from "./stores/userStore";
import profileStore from "./stores/profileStore";
import clientStore from "./stores/clientStore";
import clientUserStore from "./stores/clientUserStore";
import documentStore from "./stores/documentStore";
import employeeStore from "./stores/employeeStore";

const stores = {
  authStore,
  commonStore,
  userStore,
  profileStore,
  clientStore,
  clientUserStore,
  documentStore,
  employeeStore
};

// For easier debugging
// eslint-disable-next-line no-undef
window._____APP_STATE_____ = stores;

promiseFinally.shim();
configure({ enforceActions: 'always' })
// useStrict(true);

const theme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: "unset",
        display: "flex",
        flexWrap: "wrap"
      },
      root: {
        display: "flex",
      }
    },
    MuiTableCell: {
      root: {
        padding: "2px 16px"
      }
    },
    MUIDataTableFooter: {
      root: {
        borderCollapse: "separate"
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: "flex !important"
      },
      actions: {
        marginTop: "8px",
        display: "flex",
        flex: '1',
        justifyContent: "flex-end",
        marginBottom: "8px"
      },
      left: {
        flex:'unset'
      }
    },
    MuiAutocomplete:{
      root: {
        flexGrow: 1,
      }
    }
  },
  palette: {
    primary: {
      main: brown[800]
    }
    // secondary: {
    //   main: green[500],
    // },
  }
});

ReactDOM.render(
  <Provider {...stores}>

    <StylesProvider>

      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <HashRouter>
              <App />
          </HashRouter>
        </ThemeProvider>
      </MuiThemeProvider>

    </StylesProvider>

  </Provider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
