import UpdatePasswordComponent from "components/login/UpdatePassword";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UpdatePassword = inject("authStore", "userStore")(
  withRouter(
    observer(props => {
      const [formErrors, setFormErrors] = useState({
        passwordValidationError: ""
      });

      let { userId } = useParams();
      let token = useQuery().get("token");
      const { errors, inProgress } = props.authStore;

      const handlePasswordChange = e => {
        props.authStore.setNewPassword(e.target.value);
      };

      const handlePasswordConfirmationChange = e => {
        props.authStore.setConfirmationPassword(e.target.value);
      };

      const validateForgotPasswordUser = passwordUpdateForm => {
        if (
          passwordUpdateForm.newPassword === null ||
          passwordUpdateForm.newPassword === "" ||
          passwordUpdateForm.newPassword.length < 4 ||
          passwordUpdateForm.passwordConfirmation === null ||
          passwordUpdateForm.passwordConfirmation === "" ||
          passwordUpdateForm.passwordConfirmation.length < 4
        ) {
          setFormErrors({
            passwordValidationError: "Por favor, insira uma senha válida."
          });
          return false;
        }
        if (
          passwordUpdateForm.newPassword !==
          passwordUpdateForm.passwordConfirmation
        ) {
          setFormErrors({
            passwordValidationError: "As senhas não conferem."
          });
          return false;
        }
        setFormErrors({
          passwordValidationError: ""
        });
        return true;
      };

      const validateForm = () => {
        const { passwordUpdateForm } = props.authStore;
        return validateForgotPasswordUser(passwordUpdateForm);
      };

      const handleSubmitForm = e => {
        e.preventDefault();
        if (validateForm()) {
          props.authStore
            .updatePassword(userId, token)
            .then(() => {
              props.history.push("/");
            })
            .catch(e => {
              console.log(e);
            });
        }
      };

      return (
        <UpdatePasswordComponent
          handlePasswordChange={handlePasswordChange}
          handlePasswordConfirmationChange={handlePasswordConfirmationChange}
          handleSubmitForm={handleSubmitForm}
          passwordValidationError={formErrors.passwordValidationError}
          inProgress={inProgress}
          errors={errors}
        />
      );
    })
  )
);

export default UpdatePassword;
