/* eslint-disable no-undef */
import { observable, action, reaction, makeObservable } from "mobx";
import agent from "../agent";

class CommonStore {
  @observable appName = "Controle de Qualidade AVSEC";
  @observable shortAppName = "AVSEC";
  @observable token = window.localStorage.getItem("jwt");
  @observable appLoaded = false;

  @observable drawerOpen = false;

  @observable tags = [];
  @observable isLoadingTags = false;

  @observable notifications = [];
  @observable removeNotifications = [];

  constructor() {
    makeObservable(this);
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem("jwt", token);
        } else {
          window.localStorage.removeItem("jwt");
        }
      }
    );
  }

  @action setToken(token) {
    this.token = token;
  }

  @action setAppLoaded() {
    this.appLoaded = true;
  }

  @action setDrawer(boolean) {
    this.drawerOpen = boolean;
  }

  handleError(errors) {
    if (errors) {
      if (Array.isArray(errors)) {
        errors.forEach(error => {
          this.enqueueErrorSnackbar(error.message);
        });
      } else {
        this.enqueueErrorSnackbar(errors.message);
      }
    } else {
      this.enqueueErrorSnackbar("Ocorreu um erro inesperado.");
    }
  }

  //============================SnackBar================================
  @action enqueueCustomSnackbar(msg, opt) {
    return this.enqueueSnackbar({
      message: msg,
      options: opt
    });
  }

  @action enqueueInfoSnackbar(msg, content, persist) {
    return this.enqueueSnackbar({
      message: msg,
      options: {
        variant: "info",
        content: content,
        persist: persist
      }
    });
  }

  @action enqueueSuccessSnackbar(msg) {
    return this.enqueueSnackbar({
      message: msg,
      options: {
        variant: "success"
      }
    });
  }

  @action enqueueErrorSnackbar(errorMessage) {
    return this.enqueueSnackbar({
      message: errorMessage,
      options: {
        variant: "error"
      }
    });
  }

  @action enqueueSnackbar(snack) {
    snack.options.key = new Date().getTime() + Math.random();
    this.notifications.push(snack);
    return snack.key;
  }

  @action removeSnackbar(key) {
    this.notifications = this.notifications.filter(
      notification => notification.options.key !== key
    );
  }

  @action removeRemovalSnackbar(key) {
    this.removeNotifications = this.removeNotifications.filter(
      notification => notification.options.key !== key
    );
  }

  @action closeSnackbar(id) {
    this.removeNotifications.push(id);
  }

  @action clearSnackbarQueue() {
    this.notifications = [];
  }
  //====================================================================
}

export default new CommonStore();
