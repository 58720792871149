import { actionStatus, fileCategories } from "./enums";
import {
  green,
  yellow,
  red,
  purple,
  orange,
  blue
} from "@material-ui/core/colors";

const translate = {
  textLabels: () => {
    return {
      body: {
        noMatch: "Nenhum resultado.",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por pág.:",
        displayRows: "de"
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela"
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR"
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas"
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Deletar",
        deleteAria: "Deletar Linhas Selecionadas"
      }
    };
  },

  category: category => {
    // eslint-disable-next-line no-undef
    const map = new Map([
      [fileCategories.AUDIT, "Auditoria"],
      [fileCategories.INSPECTION, "Inspeção"],
      [fileCategories.TESTS, "Testes"],
      [fileCategories.COMMON, "Geral"]
    ]);
    return map.get(category);
  },

  actionStatusColorMessage: status => {
    let color = undefined;
    let message;
    switch (status) {
      case actionStatus.EM_ANDAMENTO:
        color = yellow[600];
        message = "Em Andamento";
        break;
      case actionStatus.ATRASADA:
        color = red[600];
        message = "Atrasado";
        break;
      case actionStatus.FINALIZADA_ATRASO:
        color = orange[600];
        message = "Finalizada com Atraso";
        break;
      case actionStatus.AGENDADA:
        color = blue[600];
        message = "Agendada";
        break;
      case actionStatus.CONFORME:
        color = green[600];
        message = "Finalizada";
        break;
      default:
        color = purple[600];
        message = "Não Iniciada";
    }

    return { color: color, message: message };
  },

  getStatusesFriendlyNames() {
    let statuses = [];
    for (const s of Object.keys(actionStatus)) {
      statuses.push(translate.actionStatusColorMessage(s).message);
    }
    return statuses;
  }
};

export default translate;
