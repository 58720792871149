import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";

const Notifier = (props) => {
    let displayed = [];

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    autorun(() => {
        const { notifications = [] } = props.commonStore;
        notifications.forEach((notification) => {
            // Do nothing if snackbar is already displayed
            if (displayed.includes(notification.options.key)) return;

            // Display snackbar using notistack
            let options = { ...notification.options };
            options.content = notification.options.content ?? undefined;
            props.enqueueSnackbar(notification.message, options);

            // Keep track of snackbars that we've displayed
            storeDisplayed(notification.options.key);
            // Dispatch action to remove snackbar from mobx store
            props.commonStore.removeSnackbar(notification.options.key);
        });
    });

    autorun(() => {
        const { removeNotifications = [] } = props.commonStore;
        removeNotifications.forEach((notification) => {
            props.closeSnackbar(notification);
            if (notification) props.commonStore.removeRemovalSnackbar(notification.options.key);
        });
    });

    return null;
}

export default withSnackbar(inject("commonStore")(observer(Notifier)));
