import { observable, action, makeObservable } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import commonStore from "./commonStore";
import clientUserStore from "./clientUserStore";

import dayjs from "../utils/dayjs";
import { actionStatus } from "../utils/enums";
class ClientStore {
  constructor() {
    makeObservable(this);
  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable clientList = [];

  @observable selectingAudit = undefined;

  @observable clientActionSelectedDate = null;

  @observable editingClient = {
    address: {},
    audit: {},
    inspection: {},
    tests: {}
  };
  @observable editingClientErrors = {};

  @observable selectedClient = null;

  @observable uploadPercent = 0;
  @observable currentLogoUploading = null;

  @observable clientActionFilter = null;
  @observable clientStatusFilter = [];
  //======================Client Section=================================

  @action setEditingClientById(id) {
    this.inProgress = true;
    this.clearEditingClient();
    return agent.Client.get(id)
      .then(
        action(data => {
          this.editingClient = data;
          clientUserStore.setUsers(data.users);
        })
      )
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action setEditingClientErrorsAttribute = (attr, value) => {
    this.editingClientErrors[attr] = value;
  };

  @action setEditingClientAttribute = (attr, value) => {
    this.editingClientErrors[attr] = undefined;
    this.editingClient[attr] = value;
  };

  @action setEditingClientAddressAttribute = (attr, value) => {
    this.editingClient.address[attr] = value;
  };

  @action loadClients() {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Client.all()
      .then(
        action(data => {
          console.log("Clients Loaded!");
          for (const client of data) {
            client.statusAudit = this.getStatus(client.audit);
            client.statusInspection = this.getStatus(client.inspection);
            client.statusTests = this.getStatus(client.tests);
          }
          this.clientList = data;
        })
      )
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action saveClient = () => {
    this.inProgress = true;
    this.errors = undefined;
    this.editingClient.users = clientUserStore.users;
    return agent.Client.save(this.editingClient)
      .then(() => {
        commonStore.enqueueSuccessSnackbar("Cliente salvo!");
        this.reset();
        this.loadClients();
      })
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          this.inProgress = false;
          throw err;
        })
      );
  };

  @action deleteClient = id => {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Client.delete(id)
      .then(() => {
        commonStore.enqueueSuccessSnackbar("Cliente deletado!");
        this.reset();
        this.loadClients();
      })
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          this.inProgress = false;
          throw err;
        })
      );
  };

  @action pullSelectedClient() {
    this.inProgress = true;
    return agent.Client.get(userStore.currentUser.client)
      .then(
        action(data => {
          this.selectedClient = data;
        })
      )
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action setSelectedClient(value) {
    this.selectedClient = value;
  }
  //========================Client Filter===================
  @action setClientFilter(action, statuses) {
    if (!Array.isArray(statuses)) {
      statuses = [statuses];
    }
    this.clientActionFilter = action;
    this.clientStatusFilter = statuses;
  }

  //=========================Logo Section===================
  @action setUploadingLogo(value) {
    this.currentLogoUploading = value;
  }

  @action uploadLogo(uploadingSnackId) {
    this.uploadingSnackId = uploadingSnackId;
    const client = this.editingClient;
    const clientId = client.id;
    return agent.Client.uploadLogo(this.currentLogoUploading, clientId, e => {
      this.setUploadPercent(parseInt(e.percent));
    })
      .then(
        action(() => {
          commonStore.closeSnackbar(this.uploadingSnackId);
          commonStore.enqueueSuccessSnackbar("Logo salvo com sucesso!");
          this.uploadPercent = 0;
        })
      )
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.closeSnackbar(this.uploadingSnackId);
          commonStore.handleError(this.errors);
          this.inProgress = false;
          throw err;
        })
      );
  }

  @action setUploadPercent(value) {
    this.uploadPercent = value;
  }

  @action resetUploading() {
    this.uploadPercent = 0;
    this.currentLogoUploading = null;
  }

  //=========================Action Section===================
  @action setClientActionSelectedDate = value => {
    this.clientActionSelectedDate = value;
  };
  @action setAuditStartDate = () => {
    this.editingClient.audit = { transient: true };
    this.editingClient.audit.start_date = this.clientActionSelectedDate;
    this.editingClient.audit.expected_end_date = this.calculateEndDate(
      this.editingClient.audit.start_date
    );
    this.clientActionSelectedDate = null;
  };
  @action setInspectionStartDate = () => {
    this.editingClient.inspection = { transient: true };
    this.editingClient.inspection.start_date = this.clientActionSelectedDate;
    this.editingClient.inspection.expected_end_date = this.calculateEndDate(
      this.editingClient.inspection.start_date
    );
    this.clientActionSelectedDate = null;
  };
  @action setTestsStartDate = () => {
    this.editingClient.tests = { transient: true };
    this.editingClient.tests.start_date = this.clientActionSelectedDate;
    this.editingClient.tests.expected_end_date = this.calculateEndDate(
      this.editingClient.tests.start_date
    );
    this.clientActionSelectedDate = null;
  };
  @action endAudit = () => {
    this.editingClient.audit.end_date = new Date();
  };
  @action endInspection = () => {
    this.editingClient.inspection.end_date = new Date();
  };
  @action endTests = () => {
    this.editingClient.tests.end_date = new Date();
  };

  //=========================Common=========================
  @action reset() {
    this.clearEditingClient();
    this.clearClients();
    this.clearFilters();
  }

  @action clearFilters() {
    this.clientActionFilter = null;
    this.clientStatusFilter = [];
  }

  @action clearEditingClient() {
    this.errors = undefined;
    this.editingClient = { address: {}, audit: {}, inspection: {}, tests: {} };
    this.clearEditingClientErrors();
    clientUserStore.reset();
    this.resetUploading();

    this.clearSelectedClient();
  }

  @action clearSelectedClient() {
    this.selectedClient = null;
  }

  @action clearEditingClientErrors() {
    this.editingClientErrors = {};
  }

  @action clearClients() {
    this.clientList = [];
  }

  getStatus(actionItem) {
    if (!actionItem || !actionItem.start_date || actionItem.start_date === "")
      return actionStatus.NAO_INICIADA;

    const now = dayjs();
    const start = dayjs(actionItem.start_date);
    const expected = dayjs(actionItem.expected_end_date);
    const end = actionItem.end_date ? dayjs(actionItem.end_date) : null;

    if (end) {
      if (end.isAfter(expected)) return actionStatus.FINALIZADA_ATRASO;
    } else {
      if (start.isAfter(now)) return actionStatus.AGENDADA;
      if (expected.isAfter(now)) {
        return actionStatus.EM_ANDAMENTO;
      } else {
        return actionStatus.ATRASADA;
      }
    }

    return actionStatus.CONFORME;
  }

  getClientsByStatus(category, status) {
    if (!Array.isArray(status)) {
      status = [status];
    }

    const list = this.clientList.filter(client => {
      return status.includes(client[category]);
    });
    return list;
  }

  isEditingUser() {
    return !(this.editingUser === undefined);
  }

  calculateEndDate(date) {
    return dayjs(date)
      .add(30, "day")
      .toDate();
  }
}

export default new ClientStore();
