import Banner from './Banner';
import MenuView from '../../components/menu/MenuView';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route, withRouter } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import Dashboard from 'pages/Dashboard';
import Clients from 'pages/Client/Clients';
import SingleClient from 'pages/Client/SingleClient';
import Documents from 'pages/Documents';
import Audit from 'pages/ClientActions/Audit';
import Inspection from 'pages/ClientActions/Inspection';
import Tests from 'pages/ClientActions/Tests';
import Employees from 'pages/Employees';
import Profile from "pages/Profile";
import HomeSelector from './HomeSelector'

const Home = inject("commonStore")(withRouter(observer((props) => {

  return (
    <MenuView>
      <Switch>
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/client" component={SingleClient} />
        <PrivateRoute path="/clients" component={Clients} />
        <PrivateRoute path="/documents" component={Documents} />
        <PrivateRoute path="/audit" component={Audit} />
        <PrivateRoute path="/inspection" component={Inspection} />
        <PrivateRoute path="/tests" component={Tests} />
        <PrivateRoute path="/employees" component={Employees} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/" component={HomeSelector} />
      </Switch>
    </MenuView>
  );
})))

Home.propTypes = {

}

export default Home;