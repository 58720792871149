import SignInSide from 'components/login/SignInSide'
import React from 'react'
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("commonStore","authStore", "userStore")
@withRouter
@observer
class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            userValidationError: '',
            passwordValidationError: ''
        }
    }

    componentDidMount () {
        const { currentUser } = this.props.userStore;

        if (currentUser) {
            this.props.history.push("/");
        }
    }

    componentWillUnmount () {
        this.props.authStore.reset();
    }

    handleUserChange = e => {
        this.setState({ userValidationError: '' });
        this.props.authStore.setUser(e.target.value);
    };

    handlePasswordChange = e => {
        this.setState({ passwordValidationError: '' });
        this.props.authStore.setPassword(e.target.value);
    };

    handleSubmitForm = e => {
        e.preventDefault();

        if (this.validateForm()) {
            this.props.authStore.login()
                .then(() => {
                    this.props.history.push("/")
                })
        }
    };

    validateForm = e => {
        const { values } = this.props.authStore;
        return this.validateUser(values.user) & this.validatePassword(values.password);
    }

    validateUser = input => {
        if (input === null || input === '') {
            this.setState({ userValidationError: "Por favor, insira um usuário/email válido." });
            return false
        }
        return true;
    }

    validatePassword = pw => {
        return (pw != null && pw != undefined && pw != '') || this.setState({ passwordValidationError: "Por favor, preencha sua senha." });
    }

    render () {
        const { values, errors, inProgress } = this.props.authStore;


        return <SignInSide
            handlePasswordChange={this.handlePasswordChange}
            handleUserChange={this.handleUserChange}
            handleSubmitForm={this.handleSubmitForm}
            values={values}
            inProgress={inProgress}
            errors={errors}
            userValidationError={this.state.userValidationError}
            passwordValidationError={this.state.passwordValidationError}
        />
    }
}
export default Login;