import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles"
import NumberFormat from 'react-number-format';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MaterialContainer from '@material-ui/core/Container';

const Title = styled.label`
    font-size: 20px;
    margin-bottom: 20px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px 14px;
    //Below will work now!
    background-color: ${props => props.theme.palette};
`;

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: stretch;
`

const AttributesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Attributes = observer(props => {
    let { client, clientErrors } = props;

    const handleChange = ({ target }) => {
        props.handleClientAttributeChange(target.name, target.value);
    };

    const handleValueChange = (name, values) => {
        props.handleClientAttributeChange(name, values.value);
    };

    const handleAddressChange = ({ target }) => {
        props.handleClientAddressAttributeChange(target.name, target.value);
    };

    const handleAddressValueChange = (name, values) => {
        props.handleClientAddressAttributeChange(name, values.value);
    };

    return (
        <Grid container
            spacing={1}
            justify="center"
            alignItems="stretch">
            <Grid item xs={12}>
                <TextField
                    id="name-input"
                    label="Nome/Razão"
                    name="name"
                    variant="outlined"
                    value={client.name ?? ""}
                    onChange={handleChange}
                    error={!!clientErrors.name}
                    helperText={clientErrors.name}
                    fullWidth
                    required />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="airport-input"
                    label="Aeroporto"
                    name="airport"
                    variant="outlined"
                    value={client.airport ?? ""}
                    onChange={handleChange}
                    error={!!clientErrors.airport}
                    helperText={clientErrors.airport}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <NumberFormat
                    customInput={TextField} mask="_"
                    format="##.###.###/####-##"
                    id="cnpj-input"
                    label="CNPJ"
                    name="cnpj"
                    variant="outlined"
                    value={client.cnpj ?? ""}
                    onValueChange={(vals) => handleValueChange('cnpj', vals)}
                    error={!!clientErrors.cnpj}
                    helperText={clientErrors.cnpj}
                    fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <Grid container
                    spacing={1}
                    alignItems="stretch">
                    <Grid item xs={12} md={6}>
                        <NumberFormat
                            customInput={TextField} isNumericString
                            format="################"
                            id="state-inscription-input"
                            label="Inscrição Estadual"
                            name="state_registration"
                            variant="outlined"
                            value={client.state_registration ?? ""}
                            onValueChange={(vals) => handleValueChange('state_registration', vals)}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberFormat
                            customInput={TextField} isNumericString
                            format="######################"
                            id="city-inscription-input"
                            label="Inscrição Municipal"
                            name="city_registration"
                            variant="outlined"
                            value={client.city_registration ?? ""}
                            onValueChange={(vals) => handleValueChange('city_registration', vals)}
                            fullWidth />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container
                    spacing={1}
                    alignItems="stretch">
                    <Grid item xs={12} md={4}>
                        <NumberFormat
                            customInput={TextField} mask="_"
                            format="#####-###"
                            id="cep-input"
                            label="CEP"
                            name="cep"
                            variant="outlined"
                            value={client.address.cep ?? ""}
                            onValueChange={(vals) => handleAddressValueChange('cep', vals)}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="city-input"
                            label="Cidade"
                            name="city"
                            variant="outlined"
                            value={client.address.city ?? ""}
                            onChange={handleAddressChange}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="bairro-input"
                            label="Bairro"
                            name="neighborhood"
                            variant="outlined"
                            value={client.address.neighborhood ?? ""}
                            onChange={handleAddressChange}
                            fullWidth />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="endereco-input"
                    label="Endereço"
                    name="street"
                    variant="outlined"
                    value={client.address.street ?? ""}
                    onChange={handleAddressChange}
                    fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="email-input"
                    label="E-mail"
                    name="email"
                    variant="outlined"
                    value={client.email ?? ""}
                    onChange={handleChange}
                    error={!!clientErrors.email}
                    helperText={clientErrors.email}
                    fullWidth required />
            </Grid>

            <Grid item xs={12}>
                <NumberFormat
                    customInput={TextField}
                    format="(##) #########"
                    id="phone-input"
                    label="Telefone 1"
                    name="phone1"
                    variant="outlined"
                    value={client.phone1 ?? ""}
                    onValueChange={(vals) => handleValueChange('phone1', vals)}
                    error={!!clientErrors.phone1}
                    helperText={clientErrors.phone1}
                    fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <NumberFormat
                    customInput={TextField}
                    format="(##) #########"
                    id="phone2-input"
                    label="Telefone 2"
                    name="phone2"
                    variant="outlined"
                    value={client.phone2 ?? ""}
                    onValueChange={(vals) => handleValueChange('phone2', vals)}
                    fullWidth />
            </Grid>
        </Grid>
    );
});

@inject('userStore', 'commonStore')
@observer
class ClientAttributes extends React.Component {
    render () {
        return (
            <Container>
                <Title>Cliente </Title>
                <Attributes {...this.props} />
            </Container>
        );
    }
}

export default ClientAttributes;
