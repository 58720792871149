import logo from './logo.svg';
import './App.css';
import React from 'react';
import Header from "./components/Header";
import { Switch, Route, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { StylesProvider, withStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import { SnackbarProvider } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import PrivateRoute from "./components/PrivateRoute";

import Login from 'pages/Login';
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import userStore from 'stores/userStore';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';

const version = require('../package.json').version
console.log(...[ // eslint-disable-line
  `%c QSMS AVSEC %c v${version} ${process.env.REACT_APP_ENV} %c`,
  'background:#1e33d5 ; padding: 3px; color: #fff; border-radius: 3px 0 0 3px;',
  `background:#e0a800 ; padding: 3px; color: #000; `,
  'background:transparent;'
].filter(Boolean))

const styles = {
  snackbarContainer: {
    marginLeft: '60px',
    height: 'auto',
  },
};
@inject("commonStore", "userStore")
@withRouter
@observer
class App extends React.Component {
  componentDidMount() {
    if (this.props.commonStore.token) {
      this.props.userStore
        .pullUser()
        .finally(() => this.props.commonStore.setAppLoaded());
    } else {
      this.props.commonStore.setAppLoaded();
    }
  }

  render() {
    const { classes } = this.props;

    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key);
    };

    const IconButtonStyled = styled(IconButton)`
      color: white;
    `;

    if (this.props.commonStore.appLoaded) {
      return (
        <div>
          <SnackbarProvider
            ref={notistackRef}
            classes={{ containerRoot: classes.snackbarContainer }}
            action={key => (
              <IconButtonStyled
                aria-label="close notification"
                onClick={onClickDismiss(key)}
              >
                <CloseIcon />
              </IconButtonStyled>
            )}
          >
            <StylesProvider>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/recovery" component={ForgotPassword} />
                <Route
                  path="/user/:userId/reset-password"
                  component={UpdatePassword}
                />

                <Route path="/" component={Home} />
              </Switch>
            </StylesProvider>
          </SnackbarProvider>
        </div>
      );
    }
    return <Route path="/login" component={Login} />;
  }
}

export default withStyles(styles)(App);
