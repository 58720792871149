import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';
import clientStore from './clientStore'
import employeeStore from './employeeStore'
import documentStore from './documentStore'
import { computed } from 'mobx';
class UserStore {

  constructor() {
    makeObservable(this)
  }

  @observable currentUser = undefined;
  @observable loadingUser;
  @observable updatingUser;
  @observable updatingUserErrors;

  @action pullUser () {
    this.loadingUser = true;
    return agent.Auth.current()
      .then(action(({ user }) => { this.currentUser = user; }))
      .then(async () => {
        if (this.currentUser && this.currentUser.profile === "CLIENT") {
          await clientStore.pullSelectedClient();
        }
        this.preload();
      })
      .catch(action((err) => {
        throw err;
      }))
      .finally(action(() => {
        this.loadingUser = false;

      }))
  }

  @action updateUser (newUser) {
    this.updatingUser = true;
    return agent.Auth.save(newUser)
      .then(action(({ user }) => { this.currentUser = user; }))
      .finally(action(() => { this.updatingUser = false; }))
  }

  @action forgetUser () {
    this.currentUser = undefined;
  }

  @action preload () {

    if (this.isAdmin) employeeStore.loadEmployees();
    if (this.isAdmin || this.isEmployee) clientStore.loadClients();
    if (this.isClient) {
      documentStore.loadCommonDocuments();
      documentStore.loadAuditDocuments();
      documentStore.loadInspectionDocuments();
      documentStore.loadTestsDocuments();
    }

  }

  @computed get getUserProfile () {
    if (!this.currentUser) return null
    return this.currentUser.profile;
  }

  @computed get isEmployee () {
    return this.currentUser && this.currentUser.profile === "EMPLOYEE";
  }

  @computed get isAdmin () {
    return this.currentUser && this.currentUser.profile === "ADMIN";
  }

  @computed get isClient () {
    return this.currentUser && this.currentUser.profile === "CLIENT";
  }

  @computed get isCompany () {
    return this.isEmployee || this.isAdmin;
  }

}

export default new UserStore();
