import styled from "styled-components";
import { useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const StyledSelect = styled(Select)`
  min-width: 80px;
`;

const VersionSpan = styled.span`
  color: ${props => { return props.last ? "green" : "blue";
  }};
`;

const VersionSelector = props => {
  const { handleChange, documentIndex, selected } = props;

  const onChangeSelect = event => {
    handleChange(documentIndex, event.target.value);
  };

  return (
    <FormControl variant="outlined">
      <StyledSelect
        value={selected}
        onChange={onChangeSelect}
      >
        {props.versions &&
          props.versions.map((vObj, index) => {
            return (
              <MenuItem key={index} value={vObj._id}>
                <VersionSpan last={index + 1 === props.versions.length}>
                  V{vObj.name}
                </VersionSpan>
              </MenuItem>
            );
          })}
      </StyledSelect>
    </FormControl>
  );
};

export default VersionSelector;
