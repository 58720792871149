import React from "react";
import { inject, observer, PropTypes } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { MainListItems, SecondaryListItems } from "components/menu/MenuItems";
import { withStyles } from "@material-ui/core/styles";
import Notifier from "components/Notifier";
import { isMobileView } from "utils/breakpoints";

const drawerWidth = 240;

const useStyles = theme => ({
  root: {
    display: "flex",
    height: "100%"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    marginLeft: 16,
    flexGrow: 1
  },
  drawerPaper: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  },
  scrollable: {
    height: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  company: {
    position: "absolute",
    height: "20em",
    opacity: 0.4,
    right: "5px",
    bottom: "5px",
    zIndex: -5
  },
  container: {
    padding: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    overflowX: "hidden"
  },
  logo: {}
});

const MenuView = inject("commonStore", "userStore", "authStore", "clientStore")(
  withRouter(
    observer(props => {
      const handleTabChange = tab => {
        if (props.location.query.tab === tab) return;
        props.router.push({ ...props.location, query: { tab } });
      };

      const handleDrawerOpen = () => {
        props.commonStore.setDrawer(true);
      };

      const handleDrawerClose = () => {
        props.commonStore.setDrawer(false);
      };

      const {
        classes,
        commonStore,
        children,
        userStore,
        clientStore
      } = props;
      const { selectedClient } = clientStore;

      const logoSrc = selectedClient?.logo;
      const mobile = isMobileView();

      return (
        <div className={classes.root}>
          {/* <CssBaseline /> */}
          <AppBar
            position="absolute"
            className={clsx(
              classes.appBar,
              commonStore.drawerOpen && classes.appBarShift
            )}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  commonStore.drawerOpen && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>

              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="Logo"
                className={classes.logo}
                height="40"
              />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {mobile ? commonStore.shortAppName : commonStore.appName}
              </Typography>
              {/* <IconButton color="inherit">
              <Badge badgeContent={3} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !commonStore.drawerOpen && classes.drawerPaperClose
              )
            }}
            open={commonStore.drawerOpen}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div className={classes.toolbarContainer}>
              <div>
                <Divider />
                <List>
                  <MainListItems
                    isAdmin={userStore.isAdmin}
                    isEmployee={userStore.isEmployee}
                  />
                </List>
              </div>
              <div>
                <Divider />
                <List>
                  <SecondaryListItems
                    isAdmin={userStore.isAdmin}
                    isEmployee={userStore.isEmployee}
                  />
                </List>
              </div>
            </div>
          </Drawer>
          <Notifier />
          <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.scrollable}>
              {!mobile && <img className={classes.company} src={logoSrc} />}
              <Container maxWidth={false} className={classes.container}>
                {children}
              </Container>
            </div>
          </div>
        </div>
      );
    })
  )
);

// MainView.propTypes = {
//   children: PropTypes.arrayOrObservableArray,
// }

export default withStyles(useStyles)(MenuView);
