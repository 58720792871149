import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UserIcon from '@material-ui/icons/AssignmentInd';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialContainer from '@material-ui/core/Container';
import { withTheme } from "@material-ui/core/styles"

import ConfirmationDialog from 'components/ui/ConfirmationDialog';

const Title = styled.label`
    font-size: 20px;
    margin-bottom: 20px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px 14px;
    //Below will work now!
    background-color: ${props => props.theme.palette};
`;

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: stretch;
`

const CustomListItemIcon = styled(ListItemIcon)`
  min-width:28px;
`;

const Separator = styled.div`
    margin: 0 4px;
`;

const ListUsers = observer(props => {

    const [open, setOpen] = React.useState(false);
    const [deleteItem, setDeleteItem] = React.useState(null);
    const { inProgress } = props.clientUserStore;

    const deleteClickHandle = () => {
        props.clientUserStore.deleteEditingUser(deleteItem)
        setOpen(false);
    }

    const openDeleteDialogClickHandle = (user) => {
        setDeleteItem(user);
        setOpen(true);
    }
    const closeDeleteDialogClickHandle = () => {
        setOpen(false);
    }


    const items = props.clientUserStore.users.map((item, index) =>
        <ListItem
            key={item.username}
            button
            selected={props.clientUserStore.selectedUserIndex === index}
        >
             <ConfirmationDialog
                open={open}
                handleClose={closeDeleteDialogClickHandle}
                title="Deseja excluir esse usuário?"
                message="Não é possível desfazer essa ação."
                handleYes={deleteClickHandle}
            />
            <CustomListItemIcon>
                <UserIcon />
            </CustomListItemIcon>
            <ListItemText primary={item.name !== null && item.name !== '' ? item.name : item.username} />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={e => props.clientUserStore.setEditingUser(item)}>
                    <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={e => openDeleteDialogClickHandle(item)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>

        </ListItem>
    );
    if (inProgress) {
        return (
            <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center" marginBottom="20px">
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <List component="nav" aria-label="main mailbox folders">
                {items}
            </List>
        );
    }

});

const UserDialog = observer(props => {
    const { user, clientUserStore } = props;
    const { editingUser, editingUserErrors } = clientUserStore;

    const validate = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = true;
        if (editingUser.username === undefined || editingUser.username == null || editingUser.username === '') {
            clientUserStore.setEditingUserErrorsAttribute('username', 'Insira um usuário válido.');
            valid = false;
        }
        // if (editingUser.email === undefined || editingUser.email == null || editingUser.email === '' || !editingUser.email.match(emailRegex)) {
        //     clientUserStore.setEditingUserErrorsAttribute('email', 'Insira um e-mail válido.');
        //     valid = false;
        // }
        if (editingUser.password === undefined || editingUser.password == null || editingUser.password === '') {
            clientUserStore.setEditingUserErrorsAttribute('password', 'Insira uma senha válida.');
            valid = false;
        }
        return valid;

    };

    const save = () => {
        if (validate()) {
            props.clientUserStore.saveUser();
        }
    };

    const handleChange = ({ target }) => {
        props.clientUserStore.setEditingUserAttribute(target.name, target.value);
    };

    return (
        <Dialog open={props.clientUserStore.isEditingUser()} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Usuário</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                </DialogContentText> */}
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Nome da Pessoa ou Área"
                    type="name"
                    value={user ? user.name || '' : ""}

                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    name="email"
                    label="Endereço de Email"
                    type="email"
                    value={user ? user.email || '' : ""}
                    error={!!editingUserErrors.email}
                    helperText={editingUserErrors.email}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="username"
                    name="username"
                    label="Username"
                    type="text"
                    value={user ? user.username || '' : ""}
                    error={!!editingUserErrors.username}
                    helperText={editingUserErrors.username}
                    onChange={handleChange}
                    required
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    onChange={handleChange}
                    error={!!editingUserErrors.password}
                    helperText={editingUserErrors.password}
                    required
                    fullWidth
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={save} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
});


@inject('clientUserStore', 'clientStore')
@observer
class ClientUsers extends React.Component {
    render () {
        const handleClickOpen = () => {
            this.props.clientUserStore.newEditingUser();
        };

        const handleClose = () => {
            this.props.clientUserStore.clearEditingUser();
        };

        return (
            <Container>
                <Title>Usuários </Title>

                <ListUsers clientUserStore={this.props.clientUserStore} />
                <RowContainer>
                    <Button variant="contained" color="primary" onClick={handleClickOpen} fullWidth>
                        Adicionar Usuário
                    </Button>
                    {/* <Separator />
                    <Button variant="contained" color="primary" onClick={handleClickMock} fullWidth>
                        Mock
                    </Button> */}
                </RowContainer>

                <UserDialog handleOpen={handleClickOpen} handleClose={handleClose} clientUserStore={this.props.clientUserStore} user={this.props.clientUserStore.editingUser} />
            </Container>
        );
    }
}

export default ClientUsers;
