import { inject, observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { withSnackbar } from "notistack";
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import FloatActionButton from 'components/ui/FloatActionButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/GetApp';

import Tooltip from "components/ui/Tooltip";
import CustomToolbar from 'components/ui/CustomToolbar'
import DocumentUploadForm from 'components/documents/DocumentUploadForm'
import DownloadSnackbar from 'components/ui/DownloadSnackbar';
import ConfirmationDialog from 'components/ui/ConfirmationDialog';

import { fileCategories } from '../../utils/enums';
import dayjs from '../../utils/dayjs';
import translate from '../../utils/translate';
import VersionSelector from './VersionSelector';


import { isMobileView } from "../../utils/breakpoints";
import { array } from 'prop-types';

const CustomIcon = styled(CloudUploadIcon)`
    margin-right: ${props => props.theme.spacing(1)}px;
`
const ToolbarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

const getLastVersion = (file) => {
    if (!file && !file.versions) return {};

    return file.versions.reduce(function (prev, current) {
        return (prev.version > current.version) ? prev : current
    })
}

const getVersion = (file,versionId) => {
  if (!file && !file.versions) return {};
  const arr = file.versions.filter(function (version){
    return versionId===version._id;
  });
  return arr[0] ?? {};

}

const ClientActionDocuments = inject("documentStore", "commonStore", "clientStore", "userStore")(observer((props) => {
    const {
      fileData,
      handleRefresh,
      type,
      documentStore,
      commonStore,
      clientStore,
      userStore
    } = props;
    const { editingUploadFile, editingFileUploadErrors } = documentStore;

    const { clientList, selectedClient } = clientStore;
    const {drawerOpen } = commonStore;

    const refreshEnabled = !!selectedClient;

    const [open, setOpen] = React.useState(false);
    const [deleteFile, setDeleteFile] = React.useState(null);


    const handleVersionChange = (index, version) => {
      documentStore.setVersionSelected(fileData,index,version);
    }

    const handleClose = () => {
        documentStore.resetEditingUploadFile();
    }

    const handleUploadPercent = () => {
        return documentStore.uploadPercent;
    }

    const handleDownloadPercent = () => {
        return documentStore.downloadPercent;
    }

    const handleOldFileName= (name, version) =>{
      var parts = name.split(".");
      const filename = name.substring(0, name.lastIndexOf("."));
      return filename+'_v'+version+'.'+parts[parts.length-1];
    }

    const handleDownload = (file) => {
      const lastVersion = getLastVersion(file);
      const version = file.selected ? getVersion(file, file.selected) : lastVersion;

      const snack = (key, message) => {
          return (<DownloadSnackbar id={key} message={message} handlePercent={handleDownloadPercent} />);
      }
      const uploadingSnackId = commonStore.enqueueCustomSnackbar("Downloading...", {
          persist: true,
          content: snack,
      });
      const fileName =
        version._id === lastVersion._id
          ? version.filename
          : handleOldFileName(version.filename, version.name);
      documentStore.downloadFile(version._id, fileName, uploadingSnackId);
    }

    const handleFileUpload = () => {
        const snack = (key, message) => {
            return (<DownloadSnackbar id={key} message={message} handlePercent={handleUploadPercent} />);
        }
        const uploadingSnackId = commonStore.enqueueCustomSnackbar("Uploading...", {
            persist: true,
            content: snack,
        });
        documentStore.uploadFile(uploadingSnackId);
        documentStore.resetEditingUploadFile();
    }

    const handleUploadVersionClick = (file) => {
      documentStore.setEditingUploadFile({
        id: file._id,
        name: file.name,
        category: file.category
      });
    }

    const openDeleteDialogClickHandle = (file) => {
        setDeleteFile(file);
        setOpen(true);
    }

    const closeDeleteDialogClickHandle = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        documentStore.deleteFile(deleteFile._id, deleteFile.category)
            .then(() => {
                handleRefresh();
            });

    }

    const validateFileTypes = (filename) => {
        var parts = filename.split(".");
        const fileType = parts[parts.length - 1];

        const allowed = process.env.REACT_APP_FILE_TYPE_ALLOWED.split('|');

        if (!allowed.includes(fileType)) {
            commonStore.enqueueErrorSnackbar('Apenas formatos jpg, jpeg, png, pdf, doc, docx, xslx, xls suportados.');
            return false;
        }

        return true;
    }

    const validateFileSize = (size) => {
        const allowed = process.env.REACT_APP_MAX_FILE_SIZE_ALLOWED;

        if (size >= allowed) {
            commonStore.enqueueErrorSnackbar('Apenas arquivos menores que 75Mb permitidos.');
            return false;
        }
        return true;
    }


    const handleSelectedClient = (client) => {
        clientStore.setSelectedClient(client);
    }

    const handleEditingFileChange = (target, value) => {
        documentStore.setEditingUploadFileAttribute(target, value);
    }

    const handleFileChange = (e) => {
        const { files } = e.target;
        if (files && files.length) {
            const file = files[0];
            if (file && validateFileTypes(file.name) && validateFileSize(file.size)) {
                documentStore.setCurrentFileUploading(file);
            }
        }
    }

    const uploadClickHandle = () => {
        documentStore.newEditingUploadFile();
    }

    const mobile = isMobileView();
    const align = mobile ? "left" : "center";
    const columns = [
      {
        name: "name",
        label: "Nome do Documento",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "createdAt",
        label: "Data Upload",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "updatedAt",
        label: "Data Atualização",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "versions",
        label: "Versões",
        options: {
          filter: false,
          searchable: false,
          viewColumns: false,
          customBodyRenderLite: function bodyRenderer(dataIndex, rowIndex) {
            return (
              <VersionSelector
                documentIndex={dataIndex}
                versions={fileData[dataIndex].versions}
                handleChange={handleVersionChange}
                selected={
                  fileData[dataIndex].selected ??
                  getLastVersion(fileData[dataIndex])._id
                }
              />
            );
          }
        }
      },
      {
        name: "Ação",
        options: {
          filter: false,
          sort: false,
          empty: true,
          searchable: false,
          viewColumns: false,
          customBodyRenderLite: function action(dataIndex, rowIndex) {
            return (
              <Box>
                <IconButton
                  aria-label="Upload Version"
                  onClick={() => handleUploadVersionClick(fileData[dataIndex])}
                >
                  <CloudUploadIcon />
                </IconButton>
                <IconButton
                  aria-label="Download"
                  onClick={() => handleDownload(fileData[dataIndex])}
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  aria-label="Delete"
                  onClick={() =>
                    openDeleteDialogClickHandle(fileData[dataIndex])
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          },
          setCellProps: value => {
            return {
              style: {
                textAlign: align
              }
            };
          },
          setCellHeaderProps: value => {
            return {
              style: {
                textAlign: "center"
              }
            };
          }
        }
      }
    ];

    const options = {
        filter: false,
        filterType: 'checkbox',
        responsive: 'vertical',
        elevation: 0,
        download: false,
        selectableRows: 'none',
        tableBodyHeight: '100%',
        rowsPerPage: 15,
        print: false,
        viewColumns: !mobile,
        textLabels: translate.textLabels(),
        // eslint-disable-next-line react/display-name
        customToolbar: () => {
            const options = clientList.map((option) => {
                const firstLetter = option.name[0].toUpperCase();
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                    ...option,
                };
            });
            return (
              <Box display="flex" flexGrow="1">
                {!mobile && (
                  <CustomToolbar
                    handleRefresh={handleRefresh}
                    refreshEnabled={refreshEnabled}
                  />
                )}
                {!userStore.isClient && (
                  <Tooltip
                    title="Selecione um Cliente para começar!"
                    arrow
                    open={!mobile && !selectedClient }
                    placement="bottom-start"
                  >
                    <ToolbarContainer>
                      <Autocomplete
                        id="grouped-clients"
                        freeSolo
                        options={options.sort(
                          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={option => option.firstLetter}
                        getOptionLabel={option =>
                          option.name + " - " + option.airport
                        }
                        // getOptionSelected={(option) => option.id}
                        style={{ minWidth: mobile ? 200 : 300 }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Selecione um Cliente"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, newValue) => {
                          handleSelectedClient(newValue);
                        }}
                        value={selectedClient}
                      />
                    </ToolbarContainer>
                  </Tooltip>
                )}
              </Box>
            );
        }
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        height="100%"
        bgcolor="transparent"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height="100%"
          bgcolor="transparent"
          clone
        >
          <MUIDataTable
            title={props.title}
            data={fileData.map(item => {
              const createdAt = item.createdAt
                ? dayjs(item.createdAt).format("DD/MM/YYYY - HH:mm:ss")
                : "";
              const updatedAt = item.updatedAt
                ? dayjs(item.updatedAt).format("DD/MM/YYYY - HH:mm:ss")
                : "";

              return {
                id: item.id,
                name: item.name,
                category: translate.category(item.category),
                createdAt: createdAt,
                updatedAt: updatedAt,
                versions: item.versions
              };
            })}
            columns={columns}
            options={options}
          />
        </Box>

        <FloatActionButton
          onClick={uploadClickHandle}
          left={true}
          disabled={!selectedClient}
          open={commonStore.drawerOpen}
        >
          <CustomIcon />
          Enviar Arquivo
        </FloatActionButton>
        <DocumentUploadForm
          isOpen={documentStore.isEditingUploadFile()}
          editingFile={editingUploadFile}
          confirm={handleFileUpload}
          handleFormChange={handleEditingFileChange}
          handleClose={handleClose}
          fileSelected={documentStore.currentFileUploading}
          defaultCategory={type}
          handleFileChange={handleFileChange}
          setEditingErrorsAttribute={documentStore.setEditingErrorsAttribute}
          editingErrors={editingFileUploadErrors}
        />
        <ConfirmationDialog
          open={open}
          handleClose={closeDeleteDialogClickHandle}
          title="Tem certeza que deseja excluir este documento?"
          message="Não é possível desfazer essa ação."
          handleYes={handleDelete}
        />
      </Box>
    );
}));

ClientActionDocuments.propTypes = {
    title: PropTypes.string,
    fileData: PropTypes.array,
    type: PropTypes.oneOf(Object.keys(fileCategories)),
    handleRefresh: PropTypes.func.isRequired,
}

export default withSnackbar(ClientActionDocuments);
