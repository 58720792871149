import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';
import userStore from './userStore';
import commonStore from './commonStore';

class EmployeeStore {

  constructor() {
    makeObservable(this)
  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable selectedUserIndex = -1;
  @observable editingUser = undefined;
  @observable editingUserErrors = {};

  @observable employees = [];

  //======================employees Section==================================
  @action newEditingUser () {
    this.clearEditingErrors();
    this.editingUser = {
      id: null,
      username: "",
      email: "",
      password: "",
      name: ""
    };
  }

  @action setEditingUser (value) {
    this.editingUser = { ...value };
  }

  @action setEmployees (value) {
    this.employees = value;
  }

  @action deleteEmployee (id) {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Employee.delete(id)
      .then(() => {
        this.reset();
        this.loadEmployees();
      })
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors && err.response.body.errors.body;
        commonStore.handleError(this.errors);
        this.inProgress = false;
        throw err;
      }));
  }

  @action clearEditingUser () {
    this.editingUser = undefined;
    this.clearEditingErrors();
  }

  @action saveUser () {
    this.inProgress = true;
    this.errors = undefined;
    this.editingUser.profile = 'EMPLOYEE';
    return agent.Employee.save(this.editingUser)
      .then(() => {
        commonStore.enqueueSuccessSnackbar("Funcionário salvo!");
        this.reset();
        this.loadEmployees();
      })
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors && err.response.body.errors.body;
        commonStore.handleError(this.errors);
        this.inProgress = false;
        throw err;
      }));

  }

  @action setSelectedUserIndex (value) {
    this.selectedUserIndex = value;
  }

  @action setEditingUserAttribute = (attr, value) => {
    this.editingUserErrors[attr] = undefined;
    this.editingUser[attr] = value;
  };

  @action setEditingUserErrorsAttribute = (attr, value) => {
    this.editingUserErrors[attr] = value;
  };

  @action loadEmployees () {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Employee.all()
      .then(action((data) => {
        console.log("Employees Loaded!")
        this.employees = data
      }))
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors && err.response.body.errors.body;
        commonStore.handleError(this.errors);
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  //=========================Common=========================
  @action reset () {
    this.employees = [];
    this.editingUser = undefined;
    this.clearEditingErrors();
  }

  @action clearEditingErrors(){
    this.editingUserErrors = {};
  }

  isEditingUser () {
    return !(this.editingUser === undefined);
  }

  arrayRemove (arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }
}

export default new EmployeeStore();
