import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
import styled from "styled-components";
import { brown } from "@material-ui/core/colors";

const useStyles = makeStyles({
  depositContext: {
    display: "flex",
    flex: 1
  }
});

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  color: ${props => props.color ?? brown[800]};
`;

const LinkContainer = styled.div`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
`;

const SquareNumberIndicator = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Row>
        <Title>{props.title}</Title>
        <IconContainer color={props.iconColor}>{props.icon}</IconContainer>
      </Row>
      <Typography component="p" variant="h4">
        {props.number}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {props.subtitle}
      </Typography>
      <LinkContainer>
        <Link color="primary" onClick={props.redirect}>
          {props.redirectLabel}
        </Link>
      </LinkContainer>
    </React.Fragment>
  );
};

export default SquareNumberIndicator;
