export const actions = {
    AUDIT: "AUDIT",
    INSPECTION: "INSPECTION",
    TESTS: "TESTS"
}

export const fileCategories = {
    AUDIT: "AUDIT",
    INSPECTION: "INSPECTION",
    TESTS: "TESTS",
    COMMON: "COMMON"
}

export const actionStatus = {
    NAO_INICIADA: "NAO_INICIADA",
    AGENDADA: "AGENDADA",
    EM_ANDAMENTO: "EM_ANDAMENTO",
    CONFORME: "CONFORME",
    ATRASADA: "ATRASADA",
    FINALIZADA_ATRASO: "FINALIZADA_ATRASO",

}

export default {
    actions,
    fileCategories,
    actionStatus
}