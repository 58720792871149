import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import commonStore from "./stores/commonStore";
import authStore from "./stores/authStore";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_URL;

// const encode = encodeURIComponent;

const handleErrors = err => {
  if (err && err.response && err.response.status === 401) {
    authStore.logout();
  }
  return err;
};

const responseBody = res => res.body;
const response = res => res;

const tokenPlugin = req => {
  if (commonStore.token) {
    req.set("authorization", `Token ${commonStore.token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: (url, query = "") =>
    superagent
      .get(`${API_ROOT}${url}`)
      .query(query)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body, headers = "", handleProgress) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .on("progress", event => {
        if (handleProgress) handleProgress(event);
      })
      .set(headers)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  getBlob: (url, handleProgress) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .responseType("blob")
      .on("progress", event => {
        if (handleProgress) handleProgress(event);
      })
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  postMultiPart: (url, body, handleProgress) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .on("progress", event => {
        if (handleProgress) handleProgress(event);
      })
      // .set('Content-Type','multipart/form-data')
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody)
};

const Auth = {
  current: () => requests.get("/user"),
  login: (username, password) =>
    requests.post("/users/login", { user: { username, password } }),
  save: user => requests.put("/user", { user }),
  forgotPassword: user => requests.get(`/user/${user}/reset-password`),

  updatePassword: (userId, token, pw) =>
    requests.post(`/user/${userId}/update-password`, {
      token: token,
      password: pw
    })
};

const Client = {
  get: id => requests.get("/client/" + id),
  all: () => requests.get("/client"),
  save: client => {
    return client.id
      ? requests.put("/client/" + client.id, { ...client })
      : requests.post("/client", { ...client });
  },
  delete: id => requests.del("/client/" + id),
  uploadLogo: (file, clientId, handleProgress) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append("file", file);
    return requests.post(
      "/client/" + clientId + "/logo",
      formData,
      "",
      handleProgress
    );
  }
};

const User = {
  delete: id => requests.del("/user/" + id)
};

const Employee = {
  all: () => requests.get("/employee"),
  get: id => requests.get("/user/" + id),
  save: user => {
    return user.id
      ? requests.put("/user/" + user.id, { ...user })
      : requests.post("/user", { ...user });
  },
  delete: id => requests.del("/user/" + id)
};

const Document = {
  upload: (file, uploadData, handleProgress) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append("file", file);
    formData.append("client", uploadData.clientId);
    formData.append("name", uploadData.name);
    formData.append("category", uploadData.category);

    return requests.post("/document", formData, "", handleProgress);
  },
  uploadVersion: (file, uploadData, handleProgress) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append("file", file);
    formData.append("client", uploadData.clientId);
    formData.append("name", uploadData.name);
    formData.append("category", uploadData.category);
    return requests.post(
      "/document/" + uploadData.id,
      formData,
      "",
      handleProgress
    );
  },
  download: (id, handleProgress) =>
    requests.getBlob("/document/" + id, handleProgress),
  all: (fileCategory, clientId) =>
    requests.get(
      "/document",
      "category=" + fileCategory + "&client=" + clientId
    ),
  delete: id => requests.del("/document/" + id)
};

export default {
  Auth,
  Client,
  Employee,
  Document,
  User
};
