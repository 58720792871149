import React, { useEffect } from "react";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import { withRouter, useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import ClientList from "components/client/ClientList";
import FloatActionButton from "components/ui/FloatActionButton";
import ConfirmationDialog from "components/ui/ConfirmationDialog";
import AddIcon from "@material-ui/icons/Add";

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const CustomAddIcon = styled(AddIcon)`
  margin-right: ${props => props.theme.spacing(1)}px;
`;

const Clients = inject("clientStore")(
  withRouter(
    observer(props => {
      const { clientStore } = props;
      const history = useHistory();

      const [open, setOpen] = React.useState(false);
      const [deleteId, setDeleteId] = React.useState(null);

      const addClickHandle = client => {
        clientStore.clearEditingClient();
        history.push("/client");
      };

      const editClickHandle = clientRow => {
        clientStore.setEditingClientById(clientRow.id);
        history.push("/client");
      };

      const deleteClickHandle = () => {
        setOpen(false);
        clientStore.deleteClient(deleteId);
      };

      const openDeleteDialogClickHandle = clientId => {
        setDeleteId(clientId);
        setOpen(true);
      };

      const closeDeleteDialogClickHandle = () => {
        setOpen(false);
      };

      useEffect(() => {
        props.clientStore.clearSelectedClient();
      }, []);

      const { clientList, inProgress } = props.clientStore;

      return inProgress ? (
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <FlexDiv>
          <ClientList
            theme={props.theme}
            list={clientList}
            editClick={editClickHandle}
            deleteClick={openDeleteDialogClickHandle}
            actionFilter={clientStore.clientActionFilter}
            statusFilter={clientStore.clientStatusFilter}
          />

          <FloatActionButton onClick={addClickHandle} left={true}>
            <CustomAddIcon />
            Adicionar Cliente
          </FloatActionButton>

          <ConfirmationDialog
            open={open}
            handleClose={closeDeleteDialogClickHandle}
            title="Tem certeza?"
            message="Deseja excluir o cliente?"
            handleYes={deleteClickHandle}
          />
        </FlexDiv>
      );
    })
  )
);

export default withTheme(Clients);
