import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import SquareNumberIndicator from "components/dashboard/SquareNumberIndicator";
import { actions, actionStatus } from "utils/enums";
import { inject, observer } from "mobx-react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import InspectionIcon from "@material-ui/icons/Visibility";
import TestIcon from "@material-ui/icons/PlaylistAddCheck";
import translate from "utils/translate";
import { withRouter } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        EPCabral
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Container = styled.div`
  padding: ${props => props.theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 120
  }
}));

const Dashboard = inject("clientStore")(
  withRouter(
    observer(props => {
      const { inProgress } = props.clientStore;

      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

      return inProgress ? null : (
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Auditorias em Atraso"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusAudit",
                      actionStatus.ATRASADA
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.AUDIT,
                      actionStatus.ATRASADA
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver Clientes"
                  icon={<VerifiedUserIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.ATRASADA)
                      .color
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Inspeções em Atraso"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusInspection",
                      actionStatus.ATRASADA
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.INSPECTION,
                      actionStatus.ATRASADA
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<InspectionIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.ATRASADA)
                      .color
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Testes em Atraso"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusTests",
                      actionStatus.ATRASADA
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.TESTS,
                      actionStatus.ATRASADA
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<TestIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.ATRASADA)
                      .color
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Auditorias em Andamento"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusAudit",
                      actionStatus.EM_ANDAMENTO
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.AUDIT,
                      actionStatus.EM_ANDAMENTO
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver Clientes"
                  icon={<VerifiedUserIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(
                      actionStatus.EM_ANDAMENTO
                    ).color
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Inspeções em Andamento"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusInspection",
                      actionStatus.EM_ANDAMENTO
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.INSPECTION,
                      actionStatus.EM_ANDAMENTO
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<InspectionIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(
                      actionStatus.EM_ANDAMENTO
                    ).color
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Testes em Andamento"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusTests",
                      actionStatus.EM_ANDAMENTO
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.TESTS,
                      actionStatus.EM_ANDAMENTO
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<TestIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(
                      actionStatus.EM_ANDAMENTO
                    ).color
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Auditorias Finalizadas"
                  number={
                    props.clientStore.getClientsByStatus("statusAudit", [
                      actionStatus.CONFORME,
                      actionStatus.FINALIZADA_ATRASO
                    ]).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(actions.AUDIT, [
                      actionStatus.CONFORME,
                      actionStatus.FINALIZADA_ATRASO
                    ]);
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver Clientes"
                  icon={<VerifiedUserIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.CONFORME)
                      .color
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Inspeções Finalizadas"
                  number={
                    props.clientStore.getClientsByStatus("statusInspection", [
                      actionStatus.CONFORME,
                      actionStatus.FINALIZADA_ATRASO
                    ]).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(actions.INSPECTION, [
                      actionStatus.CONFORME,
                      actionStatus.FINALIZADA_ATRASO
                    ]);
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<InspectionIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.CONFORME)
                      .color
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Testes Finalizados"
                  number={
                    props.clientStore.getClientsByStatus("statusTests", [
                      actionStatus.CONFORME,
                      actionStatus.FINALIZADA_ATRASO
                    ]).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(actions.TESTS, [
                      actionStatus.CONFORME,
                      actionStatus.FINALIZADA_ATRASO
                    ]);
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<TestIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.CONFORME)
                      .color
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Auditorias Agendadas"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusAudit",
                      actionStatus.AGENDADA
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.AUDIT,
                      actionStatus.AGENDADA
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver Clientes"
                  icon={<VerifiedUserIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.AGENDADA)
                      .color
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Inspeções Agendadas"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusInspection",
                      actionStatus.AGENDADA
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.INSPECTION,
                      actionStatus.AGENDADA
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<InspectionIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.AGENDADA)
                      .color
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <SquareNumberIndicator
                  title="Testes Agendados"
                  number={
                    props.clientStore.getClientsByStatus(
                      "statusTests",
                      actionStatus.AGENDADA
                    ).length
                  }
                  subtitle=""
                  redirect={() => {
                    props.clientStore.setClientFilter(
                      actions.TESTS,
                      actionStatus.AGENDADA
                    );
                    props.history.push(`/clients`);
                  }}
                  redirectLabel="Ver clientes"
                  icon={<TestIcon />}
                  iconColor={
                    translate.actionStatusColorMessage(actionStatus.AGENDADA)
                      .color
                  }
                />
              </Paper>
            </Grid>
            {/* <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ContractExpirations />
            </Paper>
          </Grid> */}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      );
    })
  )
);

export default Dashboard;
