
import MaterialTable from "material-table";
import MUIDataTable, { TableBody } from "mui-datatables";
import { inject, observer } from 'mobx-react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import translate from '../../utils/translate';


const EmployeeList = inject("employeeStore")(observer((props) => {
    const {inProgress} = props.employeeStore
    const columns = [
        {
            name: "name",
            label: "Nome",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email",
            label: "E-mail",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "username",
            label: "Usuário",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "Ação",
            options: {
                filter: false,
                sort: false,
                empty: true,
                searchable: false,
                viewColumns: false,
                customBodyRenderLite: function action (dataIndex, rowIndex) {
                    return (
                        <Box>
                            <IconButton
                                aria-label="Edit"
                                onClick={() => props.editClick(props.list[dataIndex])}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                aria-label="Delete"
                                onClick={() => props.deleteClick(props.list[dataIndex].id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                },
                setCellProps: value => {
                    return {
                        style: {
                            textAlign: 'center'
                        }
                    };
                },
                setCellHeaderProps: value => {
                    return {
                        style: {
                            textAlign: 'center'
                        },
                    };
                }
            }
        }
    ];

    const options = {
        filter: true,
        filterType: 'checkbox',
        responsive: 'vertical',
        elevation: 0,
        download: true,
        print: false,
        selectableRows: 'none',
        tableBodyHeight: '100%',
        rowsPerPage: 15,
        textLabels: translate.textLabels(),
    };

    return (
        inProgress ?
            <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
            :
            <Box display="flex" flexDirection="column" flexGrow={1} clone>
                <MUIDataTable
                    title={"Funcionários"}
                    data={props.list}
                    columns={columns}
                    options={options}
                />
            </Box>
    );
}))

export default EmployeeList;