import ForgotPasswordComponent from "components/login/ForgotPassword";
import React from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("authStore", "userStore")
@withRouter
@observer
class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      userValidationError: ""
    };
  }

  componentDidMount() {
    const { currentUser } = this.props.userStore;

    if (currentUser) {
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    this.props.authStore.reset();
  }

  handleUserChange = e => {
    this.setState({ userValidationError: "" });
    this.props.authStore.setForgotPasswordUser(e.target.value);
  };

  handleSubmitForm = e => {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.authStore
        .forgotPassword()
        .then(() => {
          this.props.history.push("/");
        })
    }
  };

  validateForm = () => {
    const { forgotPasswordUser } = this.props.authStore;
    return this.validateForgotPasswordUser(forgotPasswordUser);
  };

  validateForgotPasswordUser = forgotUserPassword => {
    if (forgotUserPassword === null || forgotUserPassword === "") {
      this.setState({
        userValidationError: "Por favor, insira um usuário válido."
      });
      return false;
    }
    return true;
  };

  render() {
    const { errors, inProgress } = this.props.authStore;

    return (
      <ForgotPasswordComponent
        handleUserChange={this.handleUserChange}
        handleSubmitForm={this.handleSubmitForm}
        userValidationError={this.state.userValidationError}
        inProgress={inProgress}
        errors={errors}
      />
    );
  }
}
export default ForgotPassword;
