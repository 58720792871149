import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {withTheme } from "@material-ui/core/styles"


const StyledButton = styled(Button)`

  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  &:hover {
    background-color: ${props => props.theme.palette.error.light};
  }
  //Below will work now!
  background-color: ${props => props.theme.palette};
`;

const LoggedOutView = props => {
  // if (!props.currentUser) {
  //   return (
  //     <ul className="nav navbar-nav pull-xs-right">

  //       <li className="nav-item">
  //         <Link to="/" className="nav-link">
  //           Home
  //         </Link>
  //       </li>

  //       <li className="nav-item">
  //         <Link to="/login" className="nav-link">
  //           Sign in
  //         </Link>
  //       </li>

  //       <li className="nav-item">
  //         <Link to="/register" className="nav-link">
  //           Sign up
  //         </Link>
  //       </li>

  //     </ul>
  //   );
  // }
  return null;
};

const LoggedInView = props => {
  if (props.currentUser) {
    return (
      <ul className="nav navbar-nav pull-xs-right">

        <li className="nav-item">
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/editor" className="nav-link">
            <i className="ion-compose" />&nbsp;New Post
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/settings" className="nav-link">
            <i className="ion-gear-a" />&nbsp;Settings
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to={`/@${props.currentUser.username}`}
            className="nav-link"
          >
            <img src={props.currentUser.image} className="user-pic" alt="" />
            {props.currentUser.username}
          </Link>
        </li>

      </ul>
    );
  }

  return null;
};

@inject('userStore', 'commonStore')
@observer
class Header extends React.Component {
  render () {
    return (
      <nav className="navbar navbar-light">
        <div className="container">

          {/* <Link to="/" className="navbar-brand">
            {this.props.commonStore.appName.toLowerCase()}
          </Link> */}
          {/* <StyledButton>Home!</StyledButton> */}
          <LoggedOutView currentUser={this.props.userStore.currentUser} />

          <LoggedInView currentUser={this.props.userStore.currentUser} />
        </div>
      </nav>
    );
  }
}

export default Header;
