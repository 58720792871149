
import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles"
import { Link } from 'react-router-dom';
import { autorun } from "mobx";
import { inject, observer } from 'mobx-react';
import { withRouter, useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';

import ClientAttributes from 'components/client/ClientAttributes';
import ClientUsers from 'components/client/ClientUsers';
import ClientActionItem from 'components/client/ClientActionItem';
import ListErrors from 'components/ListErrors';

import FloatActionButton from 'components/ui/FloatActionButton';

import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InspectionIcon from '@material-ui/icons/Visibility';
import TestIcon from '@material-ui/icons/PlaylistAddCheck';
import ClientLogo from 'components/client/ClientLogo';
import DownloadSnackbar from 'components/ui/DownloadSnackbar';

const CustomBox = styled(Box)`
    padding:  ${props => props.theme.spacing(3)}px;
    height: 100%;
    overflow: auto;
`;

const CustomCheckIcon = styled(CheckIcon)`
    margin-right: ${props => props.theme.spacing(1)}px;
`

const SingleClient = inject("commonStore", "clientStore", "clientUserStore")(observer((props) => {
    const { clientStore, commonStore } = props;
    const { editingClient, editingClientErrors, errors, inProgress } = clientStore;
    const history = useHistory();

    const validateClientInputs = () => {
        let valid = true;
        if (editingClient.name === undefined || editingClient.name == null || editingClient.name === '') {
            clientStore.setEditingClientErrorsAttribute('name', 'Insira um nome válido.');
            valid = false;
        }
        if (editingClient.cnpj === undefined || editingClient.cnpj == null || editingClient.cnpj === '') {
            clientStore.setEditingClientErrorsAttribute('cnpj', 'Insira um CNPJ válido.');
            valid = false;
        }
        if (editingClient.email === undefined || editingClient.email == null || editingClient.email === '') {
            clientStore.setEditingClientErrorsAttribute('email', 'Insira um e-mail válido.');
            valid = false;
        }
        if (editingClient.phone1 === undefined || editingClient.phone1 == null || editingClient.phone1 === '') {
            clientStore.setEditingClientErrorsAttribute('phone1', 'Insira um telefone válido.');
            valid = false;
        }

        return valid;
    }

    const handleSaveClick = () => {
        clientStore.clearEditingClientErrors();
        if (validateClientInputs()) {
            clientStore.saveClient()
                .then(() => {
                    history.push("/clients");
                })
        }
    }

    const handleClientAttributeChange = (attrName, value) => {
        clientStore.setEditingClientAttribute(attrName, value);
    }

    const handleClientAddressAttributeChange = (attrName, value) => {
        clientStore.setEditingClientAddressAttribute(attrName, value);
    }

    const handleUploadPercent = () => {
        return clientStore.uploadPercent;
    }

    const handleLogoUpload = () => {
        const snack = (key, message) => {
            return (<DownloadSnackbar id={key} message={message} handlePercent={handleUploadPercent} />);
        }
        const uploadingSnackId = commonStore.enqueueCustomSnackbar("Uploading...", {
            persist: true,
            content: snack,
        });
        clientStore.uploadLogo(uploadingSnackId);

    }

    return (
        <CustomBox display="flex" flexGrow={1}>
            {inProgress ?
                <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
                :
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Grid container spacing={3}
                            direction="column"
                            alignItems="stretch" >

                            <Grid item>
                                <Paper>
                                    <ClientAttributes
                                        client={editingClient}
                                        clientErrors={editingClientErrors}
                                        handleClientAttributeChange={handleClientAttributeChange}
                                        handleClientAddressAttributeChange={handleClientAddressAttributeChange} />
                                </Paper>
                            </Grid>

                            <Grid item>
                                <Paper>
                                    <ClientUsers />
                                </Paper>
                            </Grid>


                        </Grid>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Grid container spacing={3}
                            direction="column"
                            alignItems="stretch" >

                            {editingClient.id && (
                                <Grid item>
                                    <Paper>
                                        <ClientLogo handleLogoUpload={handleLogoUpload} />
                                    </Paper>
                                </Grid>
                            )}

                            <Grid item>
                                <Paper>
                                    <ClientActionItem icon={<VerifiedUserIcon />}
                                        title="Auditoria"
                                        selectedDate={clientStore.clientActionSelectedDate}
                                        startDate={editingClient.audit ? editingClient.audit.start_date : null}
                                        endDate={editingClient.audit ? editingClient.audit.end_date : null}
                                        expectedEndDate={editingClient.inspection ? editingClient.audit.expected_end_date : null}
                                        status={props.clientStore.getStatus(editingClient.audit)}
                                        setClientActionDate={props.clientStore.setAuditStartDate}
                                        setSelectedDate={props.clientStore.setClientActionSelectedDate}
                                        endActionItem={props.clientStore.endAudit}
                                        transient={editingClient.audit ? editingClient.audit.transient : false} />
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper>
                                    <ClientActionItem icon={<InspectionIcon />}
                                        title="Inspeção"
                                        selectedDate={clientStore.clientActionSelectedDate}
                                        startDate={editingClient.inspection ? editingClient.inspection.start_date : null}
                                        expectedEndDate={editingClient.inspection ? editingClient.inspection.expected_end_date : null}
                                        endDate={editingClient.inspection ? editingClient.inspection.end_date : null}
                                        status={props.clientStore.getStatus(editingClient.inspection)}
                                        setClientActionDate={props.clientStore.setInspectionStartDate}
                                        setSelectedDate={props.clientStore.setClientActionSelectedDate}
                                        endActionItem={props.clientStore.endInspection}
                                        transient={editingClient.inspection ? editingClient.inspection.transient : false}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper>
                                    <ClientActionItem icon={<TestIcon />}
                                        title="Testes"
                                        selectedDate={clientStore.clientActionSelectedDate}
                                        startDate={editingClient.tests ? editingClient.tests.start_date : null}
                                        endDate={editingClient.tests ? editingClient.tests.end_date : null}
                                        expectedEndDate={editingClient.tests ? editingClient.tests.expected_end_date : null}
                                        status={props.clientStore.getStatus(editingClient.tests)}
                                        setClientActionDate={props.clientStore.setTestsStartDate}
                                        setSelectedDate={props.clientStore.setClientActionSelectedDate}
                                        endActionItem={props.clientStore.endTests}
                                        transient={editingClient.tests ? editingClient.tests.transient : false} />
                                </Paper>
                            </Grid>

                        </Grid>
                    </Grid>
                    <FloatActionButton onClick={handleSaveClick}>
                        <CustomCheckIcon />
                    Concluir
                    </FloatActionButton>
                </Grid>
            }
        </CustomBox>
    );
}))

export default SingleClient;