import { observable, action, makeObservable } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import commonStore from "./commonStore";
import clientStore from "./clientStore";

class AuthStore {
  constructor() {
    makeObservable(this);

  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable values = {
    user: "",
    password: ""
  };

  @observable forgotPasswordUser = "";

  @observable passwordUpdateForm = {
    newPassword: "",
    passwordConfirmation: ""
  };

  @action setUser(user) {
    this.values.user = user;
  }

  @action setPassword(password) {
    this.values.password = password;
  }

  @action setForgotPasswordUser(user) {
    this.forgotPasswordUser = user;
  }

  @action setNewPassword(pw) {
    this.passwordUpdateForm.newPassword = pw;
  }

  @action setConfirmationPassword(pw) {
    this.passwordUpdateForm.passwordConfirmation = pw;
  }

  @action reset() {
    this.values.username = "";
    this.values.email = "";
    this.values.password = "";
  }

  @action login() {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.login(this.values.user, this.values.password)
      .then(({ user }) => commonStore.setToken(user.token))
      .then(async () => {
        await userStore.pullUser();
      })
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body &&
            err.response.body.errors;

          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action forgotPassword() {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.forgotPassword(this.forgotPasswordUser)
      .then(() =>
        commonStore.enqueueSuccessSnackbar("E-mail enviado com sucesso.")
      )
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body &&
            err.response.body.errors;
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action updatePassword(userId, token) {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.updatePassword(
      userId,
      token,
      this.passwordUpdateForm.newPassword
    )
      .then(() =>
        commonStore.enqueueSuccessSnackbar("Senha alterada com sucesso!")
      )
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body &&
            err.response.body.errors;

          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  @action logout() {
    commonStore.setToken(undefined);
    userStore.forgetUser();
    clientStore.reset();
    // eslint-disable-next-line no-undef
    return Promise.resolve();
  }
}

export default new AuthStore();
