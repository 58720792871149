/* eslint-disable no-undef */
import { css } from 'styled-components';
import { useEffect, useState } from 'react';

export const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xg: 1548,
};

const keys = Object.keys(breakpoints);

export const belowSize = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args) => {
    return css`
      @media (max-width: ${breakpoints[label] + 'px'}) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
},{});

export const aboveSize = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args) => {
    return css`
      @media (min-width: ${breakpoints[label] + 'px'}) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
},{});

export const WindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    let isActive = true;
    const handleResize = () => {
       if (isActive) setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
       isActive = false;
     };
  }, [windowWidth]);
  return windowWidth;
};

export const isMobileView = () => {
  return WindowSize() < breakpoints.md;
};
