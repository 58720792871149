import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel (props) {
    const variant = props.value && props.value > 0 ? 'determinate' : 'indeterminate';
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant={variant} {...props} />
            </Box>
            {variant =='determinate' &&
                <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            }
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


const LinearWithValueLabel = (props) => {
    const { value } = props;

    return (
        <Box>
            <LinearProgressWithLabel value={value} />
        </Box>
    );
}

export default LinearWithValueLabel;