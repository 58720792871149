import React from "react";
import styled from 'styled-components';
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Button from '@material-ui/core/Button';

const Container = styled.div`
    padding:  ${props => props.theme.spacing(3)}px;
`

@inject("authStore")
@withRouter
@observer
class Profile extends React.Component {
  handleClickLogout = () =>
    this.props.authStore.logout().then(() => this.props.history.replace("/"));

  render () {
    return (
      <Container>
        <h2>Perfil</h2>

        <Button
          variant="contained" color="primary"
          onClick={this.handleClickLogout}
        >
          Logout
        </Button>
      </Container>
    );
  }
}

export default Profile;
