import { action, makeObservable, makeAutoObservable, observable } from 'mobx';
import FileDownload from 'js-file-download';

import agent from '../agent';
import commonStore from './commonStore';
import userStore from './userStore';



import { fileCategories } from '../utils/enums';
import clientStore from './clientStore';
class DocumentStore {
  constructor() {
    makeObservable(this);
  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable commonDocuments = [];
  @observable auditDocuments = [];
  @observable inspectionDocuments = [];
  @observable testsDocuments = [];

  @observable editingUploadFile = undefined;
  @observable uploadingSnackId = undefined;
  @observable uploadPercent = 0;
  @observable currentFileUploading = null;
  @observable editingFileUploadErrors = {};

  @observable downloadPercent = 0;
  @observable downloadingSnackId = undefined;

  @action newEditingUploadFile() {
    this.editingUploadFile = {
      name: "",
      category: ""
    };
  }

  @action setEditingUploadFile(file){
    this.editingUploadFile = file;
  }

  @action setEditingUploadFileAttribute = (attr, value) => {
    this.editingFileUploadErrors[attr] = undefined;
    this.editingUploadFile[attr] = value;
  };

  @action setCurrentFileUploading(file) {
    this.editingFileUploadErrors.file = undefined;
    this.currentFileUploading = file;
  }

  @action downloadFile(fileId, fileName, downloadingSnackId) {
    this.downloadingSnackId = downloadingSnackId;

    return agent.Document.download(fileId, e => {
      this.computeDownloadPercent(e);
    })
      .then(
        action(data => {
          commonStore.closeSnackbar(this.downloadingSnackId);
          this.resetDownload();
          FileDownload(data, fileName);
          commonStore.enqueueSuccessSnackbar("Arquivo baixado com sucesso!");
        })
      )
      .catch(
        action(err => {
          commonStore.closeSnackbar(this.downloadingSnackId);
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          this.inProgress = false;
          throw err;
        })
      );
  }

  @action deleteFile(fileId) {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Document.delete(fileId)
      .then(() => {
        commonStore.enqueueSuccessSnackbar("Documento deletado com sucesso!");
        this.reset();
      })
      .catch(
        action(err => {
          this.errors =
            err.response &&
            err.response.body &&
            err.response.body.errors &&
            err.response.body.errors.body;
          commonStore.handleError(this.errors);
          this.inProgress = false;
          throw err;
        })
      );
  }

  @action uploadFile(uploadingSnackId) {
    this.uploadingSnackId = uploadingSnackId;
    const client = clientStore.selectedClient;
    if (!client) {
      commonStore.closeSnackbar(this.uploadingSnackId);
      this.resetUpload();
      throw "Por favor, selecione um cliente.";
    }
    const fileCategory = this.editingUploadFile.category;
    const clientId = client.id;

    if(!this.editingUploadFile.id){
      return agent.Document.upload(
        this.currentFileUploading,
        { clientId, ...this.editingUploadFile },
        e => {
          this.setUploadPercent(parseInt(e.percent));
        }
      )
        .then(
          action(() => {
            commonStore.closeSnackbar(this.uploadingSnackId);
            commonStore.enqueueSuccessSnackbar("Arquivo enviado com sucesso!");
            this.resetCategory(fileCategory);
            this.reloadFilesByCategory(fileCategory);
          })
        )
        .catch(
          action(err => {
            this.errors =
              err.response &&
              err.response.body &&
              err.response.body.errors &&
              err.response.body.errors.body;
            commonStore.closeSnackbar(this.uploadingSnackId);
            commonStore.handleError(this.errors);
            this.inProgress = false;
            throw err;
          })
        );
    }else{
      return agent.Document.uploadVersion(
        this.currentFileUploading,
        { clientId, ...this.editingUploadFile },
        e => {
          this.setUploadPercent(parseInt(e.percent));
        }
      )
        .then(
          action(() => {
            commonStore.closeSnackbar(this.uploadingSnackId);
            commonStore.enqueueSuccessSnackbar("Arquivo enviado com sucesso!");
            this.resetCategory(fileCategory);
            this.reloadFilesByCategory(fileCategory);
          })
        )
        .catch(
          action(err => {
            this.errors =
              err.response &&
              err.response.body &&
              err.response.body.errors &&
              err.response.body.errors.body;
            commonStore.closeSnackbar(this.uploadingSnackId);
            commonStore.handleError(this.errors);
            this.inProgress = false;
            throw err;
          })
        );
    }
  }

  @action loadCommonDocuments() {
    this.loadDocuments(fileCategories.COMMON).then(
      action(data => {
        this.commonDocuments = data;
        this.stopProgress();
      })
    );
  }

  @action loadAuditDocuments() {
    this.loadDocuments(fileCategories.AUDIT).then(
      action(data => {
        this.auditDocuments = data;
        this.stopProgress();
      })
    );
  }

  @action loadInspectionDocuments() {
    this.loadDocuments(fileCategories.INSPECTION).then(
      action(data => {
        this.inspectionDocuments = data;
        this.stopProgress();
      })
    );
  }

  @action loadTestsDocuments() {
    this.loadDocuments(fileCategories.TESTS).then(
      action(data => {
        this.testsDocuments = data;
        this.stopProgress();
      })
    );
  }

  async loadDocuments(type) {
    this.inProgress = true;
    this.errors = undefined;
    let data = [];

    try {
      data = await agent.Document.all(type, clientStore.selectedClient.id);
      action(() => (this.inProgress = false));
    } catch (err) {
      if (err.response.statusCode !== 404) {
        this.errors =
          err.response &&
          err.response.body &&
          err.response.body.errors &&
          err.response.body.errors.body;
        commonStore.handleError(this.errors);
      }
      this.inProgress = false;
      throw err;
    }

    return data;
  }

  @action setVersionSelected(documentList,documentId, version) {
    if(documentList[documentId].selected !== version) {
      documentList[documentId].selected = version;
      documentList[documentId] = {...documentList[documentId]};
    }
  }

  @action reloadFilesByCategory(category) {
    switch (category) {
      case fileCategories.COMMON:
        this.loadCommonDocuments();
        break;
      case fileCategories.AUDIT:
        this.loadAuditDocuments();
        break;
      case fileCategories.INSPECTION:
        this.loadInspectionDocuments();
        break;
      case fileCategories.TESTS:
        this.loadTestsDocuments();
        break;
    }
  }

  @action stopProgress() {
    this.inProgress = false;
  }

  @action setEditingErrorsAttribute = (attr, value) => {
    this.editingFileUploadErrors[attr] = value;
  };

  @action computeDownloadPercent(e) {
    var contentLength;
    if (e.lengthComputable) {
      contentLength = e.total;
    } else {
      contentLength = parseInt(
        e.target.getResponseHeader("x-decompressed-content-length"),
        10
      );
    }

    this.setDownloadPercent(
      contentLength ? (e.loaded / contentLength) * 100 : 0
    );
  }

  @action setDownloadPercent(value) {
    this.downloadPercent = value;
  }

  @action setUploadPercent(value) {
    this.uploadPercent = value;
  }

  @action resetUpload() {
    this.currentFileUploading = null;
    this.uploadingSnackId = undefined;
    this.uploadPercent = 0;
    this.resetEditingUploadFile();
  }

  @action resetDownload() {
    this.uploadingSnackId = undefined;
    this.downloadPercent = 0;
  }

  @action reset() {
    this.errors = undefined;
    this.resetUpload();
    this.resetLists();
  }

  @action resetCategory(category) {
    this.errors = undefined;
    this.resetUpload();
    switch (category) {
      case fileCategories.COMMON:
        this.commonDocuments = [];
        break;
      case fileCategories.INSPECTION:
        this.inspectionDocuments = [];
        break;
      case fileCategories.AUDIT:
        this.auditDocuments = [];
        break;
      case fileCategories.TESTS:
        this.testsDocuments = [];
        break;
    }
  }

  @action resetEditingUploadFile() {
    this.editingUploadFile = undefined;
    this.editingFileUploadErrors = {};
  }

  @action resetLists() {
    this.commonDocuments = [];
    this.auditDocuments = [];
    this.inspectionDocuments = [];
    this.testsDocuments = [];
  }

  isUploadingFile() {
    return !(this.uploadingSnackId === undefined);
  }

  isEditingUploadFile() {
    return !(this.editingUploadFile === undefined);
  }
}


export default new DocumentStore();
