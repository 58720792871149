
import React from 'react';
import styled from 'styled-components';
import { withTheme } from "@material-ui/core/styles"
import { withRouter, useHistory } from "react-router-dom";
import { inject, observer } from 'mobx-react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import EmployeeList from 'components/employee/EmployeeList';
import FloatActionButton from 'components/ui/FloatActionButton';
import ConfirmationDialog from 'components/ui/ConfirmationDialog';
import AddIcon from '@material-ui/icons/Add';

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
`;

const CustomAddIcon = styled(AddIcon)`
    margin-right: ${props => props.theme.spacing(1)}px;
`


const UserDialog = observer(props => {
    const { user, saveDisabled, editingErrors } = props;

    const save = () => {
        props.save();
    };

    const handleChange = ({ target }) => {
        props.setEditingUserAttribute(target.name, target.value);
    };

    return (
        <Dialog open={props.isEditing} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Usuário</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                </DialogContentText> */}
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Nome da Pessoa"
                    type="name"
                    value={user ? user.name || '' : ""}
                    error={!!editingErrors.name}
                    helperText={editingErrors.name}
                    onChange={handleChange}
                    required
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    name="email"
                    label="Endereço de Email"
                    type="email"
                    value={user ? user.email || '' : ""}
                    error={!!editingErrors.email}
                    helperText={editingErrors.email}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="username"
                    name="username"
                    label="Username"
                    type="text"
                    value={user ? user.username || '' : ""}
                    error={!!editingErrors.username}
                    helperText={editingErrors.username}
                    onChange={handleChange}
                    required
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    onChange={handleChange}
                    value={user ? user.password || '' : ""}
                    error={!!editingErrors.password}
                    helperText={editingErrors.password}
                    required
                    fullWidth
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={save} color="primary" disabled={saveDisabled}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
});

const Employees = inject("employeeStore")(observer((props) => {
    const { employeeStore } = props;
    const { inProgress, editingUser } = employeeStore;
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);


    const addClickHandle = () => {
        employeeStore.newEditingUser();
    }

    const validateForm = () =>{
        let valid = true;
        if (editingUser.name === undefined || editingUser.name == null || editingUser.name === '') {
            employeeStore.setEditingUserErrorsAttribute('name', 'Insira um nome válido.');
            valid = false;
        }
        // if (editingUser.email === undefined || editingUser.email == null || editingUser.email === '') {
        //     employeeStore.setEditingUserErrorsAttribute('email', 'Insira um e-mail válido.');
        //     valid = false;
        // }
        if (editingUser.username === undefined || editingUser.username == null || editingUser.username === '') {
            employeeStore.setEditingUserErrorsAttribute('username', 'Insira um usuário válido.');
            valid = false;
        }
        if (editingUser.password === undefined || editingUser.password == null || editingUser.password === '') {
            employeeStore.setEditingUserErrorsAttribute('password', 'Insira uma senha válida.');
            valid = false;
        }

        return valid;
    }

    const saveHandle = () => {
        if (validateForm()) {
            employeeStore.saveUser();
        }
    }

    const editClickHandle = (employee) => {
        employeeStore.setEditingUser(employee);
    }

    const deleteClickHandle = () => {
        employeeStore.deleteEmployee(deleteId);
        setOpen(false);
    }

    const openDeleteDialogClickHandle = (employeeId) => {
        setDeleteId(employeeId);
        setOpen(true);
    }

    const closeDeleteDialogClickHandle = () => {
        setOpen(false);
    }

    const handleClickOpen = () => {
        employeeStore.newEditingUser();
    };

    const handleClose = () => {
        employeeStore.clearEditingUser();
    };

    return (
        inProgress ?
            <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
            :
            <FlexDiv>
                <EmployeeList
                    theme={props.theme}
                    list={employeeStore.employees}
                    editClick={editClickHandle}
                    deleteClick={openDeleteDialogClickHandle} />

                <FloatActionButton onClick={addClickHandle} left={true}>
                    <CustomAddIcon />
                Adicionar Funcionário
            </FloatActionButton>
                <UserDialog
                    handleOpen={handleClickOpen}
                    handleClose={handleClose}
                    user={editingUser}
                    setEditingUserAttribute={employeeStore.setEditingUserAttribute}
                    save={saveHandle}
                    isEditing={employeeStore.isEditingUser()}
                    editingErrors={employeeStore.editingUserErrors}
                    saveDisabled={inProgress} />
                <ConfirmationDialog
                    open={open}
                    handleClose={closeDeleteDialogClickHandle}
                    title="Tem certeza?"
                    message="Deseja excluir o funcionário?"
                    handleYes={deleteClickHandle}
                />
            </FlexDiv>

    );
}))

export default withTheme(Employees);